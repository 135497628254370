import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import { NOTIFY_OPTS } from 'constants/Notifiers'
import {Translation} from "react-i18next";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import {toast} from "react-toastify";
import './PaymentAccount.scss';
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import GrayButton from "../../Custom UI/Buttons/GrayButton/GrayButton";
import i18next from "i18next";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

const passwordPlaceholder = '••••••••';
const notifyUpdateError = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_METHOD_CHANGED_ERROR') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_METHOD_CHANGED_SUCCESS') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateErrorHyperwallet = () => toast(<Translation>{ (t, { i18n }) => t('INVALID_HYPERWALLET_CREDENTIALS') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateErrorTremendous = () => toast(<Translation>{ (t, { i18n }) => t('INVALID_TREMENDOUS_CREDENTIALS') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateSuccessHyperwallet = () => toast(<Translation>{ (t, { i18n }) => t('VALID_HYPERWALLET_CREDENTIALS') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateSuccessTremendous = () => toast(<Translation>{ (t, { i18n }) => t('VALID_TREMENDOUS_CREDENTIALS') }</Translation>, NOTIFY_OPTS.autoClose);

export default class PaymentAccount extends React.Component {

  // Init

  constructor(props) {
    super(props);
    this.state = {
      isEditing:false,
      paymentType:(props.company ? props.company.payment_type : 'hyperwallet'),
      tremendousApiToken:(props.company ? props.company.tremendous_api_token : ''),
      tremendousCampaignId:(props.company ? props.company.tremendous_campaign_id : ''),
      tremendousFundingSourceId:(props.company ? props.company.tremendous_funding_source_id : ''),
      tremendousURL:(props.company ? props.company.tremendous_api_url : ''),
      hyperWalletURL:(props.company ? props.company.hyperwallet_api_url : ''),
      hyperWalletPasswordChanged:false,
      confirmPaymentTypeModalOpen:false,
      hyperWalletPassword:(props.company && props.company.has_hyperwallet_api_password ? passwordPlaceholder : ''),
      hyperWalletUsername:(props.company ? props.company.hyperwallet_api_username : ''),
      hyperWalletProgramToken:(props.company ? props.company.hyperwallet_program_token : '')
    }
  }

  // Methods

  saveButtonClicked = () => {
    const {updateHyperWalletCredentials, updateTremendousCredentials} = this.props;
    const {paymentType, tremendousURL, tremendousApiToken, tremendousCampaignId, tremendousFundingSourceId, hyperWalletURL,
      hyperWalletPassword, hyperWalletPasswordChanged, hyperWalletUsername, hyperWalletProgramToken} = this.state;
    
    if(paymentType === 'hyperwallet'){
      let newPassword = null;
      
      if(hyperWalletPasswordChanged){
        newPassword = hyperWalletPassword;
      }
      updateHyperWalletCredentials(hyperWalletProgramToken, hyperWalletUsername, newPassword, hyperWalletURL).then((newResult) => {
        this.setState({isEditing:false});
        notifyUpdateSuccessHyperwallet();
      }, error => {
        notifyUpdateErrorHyperwallet();
      });
    }
    else if(paymentType === 'tremendous'){
      updateTremendousCredentials(tremendousApiToken, tremendousCampaignId, tremendousFundingSourceId, tremendousURL).then((newResult) => {
        this.setState({isEditing:false});
        notifyUpdateSuccessTremendous();
      }, error => {
        notifyUpdateErrorTremendous();
      });
    }
  };

  cancelButtonClicked = () => {
    this.setState({
      isEditing:false,
      tremendousURL:(this.props.company && this.props.company.tremendous_api_url ? this.props.company.tremendous_api_url : ''),
      tremendousApiToken:(this.props.company ? this.props.company.tremendous_api_token : ''),
      tremendousCampaignId:(this.props.company ? this.props.company.tremendous_campaign_id : ''),
      tremendousFundingSourceId:(this.props.company ? this.props.company.tremendous_funding_source_id : ''),
      hyperWalletPasswordChanged:false,
      hyperWalletURL:(this.props.company && this.props.company.hyperwallet_api_url ? this.props.company.hyperwallet_api_url : ''),
      hyperWalletPassword:(this.props.company && this.props.company.has_hyperwallet_api_password ? passwordPlaceholder : ''),
      hyperWalletUsername:(this.props.company ? this.props.company.hyperwallet_api_username : ''),
      hyperWalletProgramToken:(this.props.company ? this.props.company.hyperwallet_program_token : '')
    });
  };

  onPasswordBlur = (aEvent) => {
    if(!this.state.hyperWalletPasswordChanged){
      if(this.props.company && this.props.company.has_hyperwallet_api_password){
        this.setState({hyperWalletPassword:passwordPlaceholder});
      }
      else{
        this.setState({hyperWalletPassword:''});
      }
    }
  };

  onPasswordFocus = (aEvent) => {
    if(this.state.hyperWalletPassword === passwordPlaceholder){
      this.setState({hyperWalletPassword:''});
    }
  };

  render() {
    const {company, updateCompanyPaymentType} = this.props;
    const {isEditing, paymentType, tremendousApiToken, tremendousCampaignId, tremendousFundingSourceId, tremendousURL,
      hyperWalletProgramToken, hyperWalletUsername, hyperWalletPassword, hyperWalletURL, confirmPaymentTypeModalOpen} = this.state;
    
    const currentRole = roleForCompany(company);
    const paymentTriggerPermissions = ["admin", "admin_level_4"];
    
    const paymentOptions = [
      {label:'Tremendous', value:'tremendous'},
      {label:'Hyperwallet', value:'hyperwallet'}
    ];

    return (
      <div className="main-panel-inner-container">
        {paymentTriggerPermissions.includes(currentRole) ?
          <div>
            <div className="manage-payments-title-container">
              <div className="manage-payments-container-text">
                <Translation>{ (t) => t('PAYMENT_ACCOUNT') }</Translation>
              </div>
              
              <LabeledDropDown className="payment-account-title-dropdown"
                               label={<Translation>{ (t, { i18n }) => t('PAYMENT_SOURCE') }</Translation>}
                               value={paymentOptions.filter(function(option){
                                 return option.value === paymentType;
                               })}
                               options={paymentOptions}
                               handleSave={(aOption) => {
                                 if (aOption.value !== paymentType) {
                                   this.cancelButtonClicked();
                                   this.setState({confirmPaymentTypeModalOpen: true});
                                 }
                               }}
                               infoBlurb={<Translation>{(t, {i18n}) => t('PAYMENT_SOURCE_INFO')}</Translation>}
              />
            </div>
            
            <div className="payment-account-container">
              {company && paymentType === 'hyperwallet' && !company.has_valid_hyperwallet_credentials ?
                <div className="payment-error-message">
                  <Translation>{(t, {i18n}) => t('INVALID_HYPERWALLET_CREDENTIALS')}</Translation>
                </div> : null}
              {company && paymentType === 'tremendous' && !company.has_valid_tremendous_credentials ?
                <div className="payment-error-message">
                  <Translation>{(t, {i18n}) => t('INVALID_TREMENDOUS_CREDENTIALS')}</Translation>
                </div> : null}
              <LabeledTextInput className="payment-text-input"
                                required={true}
                                label={
                                  <Translation>{(t, {i18n}) => t(paymentType === 'hyperwallet' ? 'PROGRAM_TOKEN' : 'TREMENDOUS_FUNDING_ID')}</Translation>}
                                value={paymentType === 'hyperwallet' ? hyperWalletProgramToken : tremendousFundingSourceId}
                                handleSave={(aEvent) => this.setState(paymentType === 'hyperwallet' ? {hyperWalletProgramToken: aEvent.target.value} : {tremendousFundingSourceId: aEvent.target.value})}
                                placeholder={i18next.t('CLICK_HERE_TO_SELECT')}
                                disabled={!isEditing}
                                infoBlurb={
                                  <Translation>{(t, {i18n}) => t(paymentType === 'hyperwallet' ? 'PROGRAM_TOKEN_INFO' : 'TREMENDOUS_FUNDING_ID_INFO')}</Translation>}
              />
              
              <LabeledTextInput className="payment-text-input"
                                required={true}
                                label={
                                  <Translation>{(t, {i18n}) => t(paymentType === 'hyperwallet' ? 'HYPERWALLET_USERNAME' : 'TREMENDOUS_API_TOKEN')}</Translation>}
                                value={paymentType === 'hyperwallet' ? hyperWalletUsername : tremendousApiToken}
                                handleSave={(aEvent) => this.setState(paymentType === 'hyperwallet' ? {hyperWalletUsername: aEvent.target.value} : {tremendousApiToken: aEvent.target.value})}
                                placeholder={i18next.t('CLICK_HERE_TO_SELECT')}
                                disabled={!isEditing}
                                infoBlurb={
                                  <Translation>{(t, {i18n}) => t(paymentType === 'hyperwallet' ? 'HYPERWALLET_USERNAME_INFO' : 'TREMENDOUS_API_TOKEN_INFO')}</Translation>}
              />
              
              {paymentType === 'hyperwallet' ?
                <LabeledTextInput className="payment-text-input"
                                  type="password"
                                  required={true}
                                  label={<Translation>{(t, {i18n}) => t('HYPERWALLET_PASSWORD')}</Translation>}
                                  value={hyperWalletPassword}
                                  handleSave={(aEvent) => this.setState({
                                    hyperWalletPassword: aEvent.target.value,
                                    hyperWalletPasswordChanged: true
                                  })}
                                  placeholder={i18next.t('CLICK_HERE_TO_SELECT')}
                                  disabled={!isEditing}
                                  onFocus={this.onPasswordFocus}
                                  onBlur={this.onPasswordBlur}
                />
                :
                <LabeledTextInput className="payment-text-input"
                                  required={true}
                                  label={<Translation>{(t, {i18n}) => t('TREMENDOUS_CAMPAIGN_ID')}</Translation>}
                                  value={tremendousCampaignId}
                                  handleSave={(aEvent) => this.setState({tremendousCampaignId:aEvent.target.value})}
                                  placeholder={i18next.t('CLICK_HERE_TO_SELECT')}
                                  disabled={!isEditing}
                                  infoBlurb={<Translation>{(t, {i18n}) => t('TREMENDOUS_CAMPAIGN_ID_INFO')}</Translation>}
                />
              }
              
              <LabeledTextInput className="payment-text-input"
                                required={true}
                                label={<Translation>{(t, {i18n}) => t(paymentType === 'hyperwallet' ? 'HYPERWALLET_U_R_L' : 'TREMENDOUS_URL')}</Translation>}
                                value={paymentType === 'hyperwallet' ? hyperWalletURL : tremendousURL}
                                handleSave={(aEvent) => this.setState(paymentType === 'hyperwallet' ? {hyperWalletURL:aEvent.target.value} : {tremendousURL:aEvent.target.value})}
                                placeholder={i18next.t('CLICK_HERE_TO_SELECT')}
                                disabled={!isEditing}
                                infoBlurb={<Translation>{(t, {i18n}) => t(paymentType === 'hyperwallet' ? 'HYPERWALLET_U_R_L_INFO' : 'TREMENDOUS_URL_INFO')}</Translation>}
              />
              
              
              {this.state.isEditing ?
                <div className="payment-button-container">
                  <GrayButton className="payment-button"
                              name={<Translation>{(t, {i18n}) => t('CANCEL')}</Translation>}
                              onClick={this.cancelButtonClicked}/>
                  <BlueButton className="payment-button"
                              name={<Translation>{(t, {i18n}) => t('SAVE_PROPER_CAPITALIZED')}</Translation>}
                              onClick={this.saveButtonClicked}/>
                </div>
                :
                <div className="payment-button-container">
                  <BlueButton className="payment-button"
                              name={<Translation>{(t, {i18n}) => t('EDIT')}</Translation>}
                              onClick={() => this.setState({isEditing: true})}/>
                </div>
              }
            </div>
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('PAYMENTS_BLOCKED')}</Translation>
          </div>
        }

        {confirmPaymentTypeModalOpen ?
          <ConfirmationModal title={i18next.t(paymentType === 'hyperwallet' ? 'CHANGE_PAYMENT_SOURCE_TITLE_TREMENDOUS' : 'CHANGE_PAYMENT_SOURCE_TITLE_HYPERWALLET')}
                             isOpen={confirmPaymentTypeModalOpen}
                             reject={() => this.setState({confirmPaymentTypeModalOpen:false})}
                             confirm={() => {
                               let newType = paymentType === 'hyperwallet' ? 'tremendous' : 'hyperwallet';
                               updateCompanyPaymentType(company.id, newType).then((newResult) => {
                                 if(newResult.data.error){
                                   notifyUpdateError();
                                   this.setState({confirmPaymentTypeModalOpen:false})
                                 }
                                 else{
                                   this.setState({paymentType:newResult.data.data.updateCompany.company.payment_type, confirmPaymentTypeModalOpen:false});
                                   notifyUpdateSuccess();
                                 }
                               }, (newError) => {
                                 notifyUpdateError();
                               });
                             }}
                             description={i18next.t('CHANGE_PAYMENT_SOURCE_DESCRIPTION')}
          />
          :
          null
        }
      </div>
    )
  }
}
