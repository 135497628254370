import {Translation} from "react-i18next";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../constants/Notifiers";

const notifySurveyListDownloadError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_DOWNLOADING_SURVEY_LIST', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifySurveyListDownloadSuccess = (aName) => toast(<Translation>{ (t, { i18n }) => t('DOWNLOADED_SURVEY_LIST', {aName:aName}) }</Translation>, NOTIFY_OPTS.autoClose);

export function downloadSurveyList(aSurveyList, fetchSurveyListById){
  if(aSurveyList){
    fetchSurveyListById(aSurveyList.id).then((newResponse) => {
      let surveyList = newResponse.data.data.survey_list;
      let data = surveyList.data.replaceAll('=>', ':');
      data = data.replaceAll('{"label":nil, "value":nil}, ', '');
      data = data.replaceAll('{"label":nil, "value":nil}', '');
      data = data.replaceAll(', ]', ']');
      data = JSON.parse(data);
      
      let content = '';
      
      for(let item of data){
        content += item.label + ',' + item.value + '\n';
      }
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
      element.setAttribute('download', surveyList.name.replaceAll(' ', '') + '.csv');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      
      notifySurveyListDownloadSuccess(surveyList.name);
    }, (newError) => {
      let error = '';
      
      if(newError && newError.data && newError.data && newError.data.error){
        error = newError.data.error;
      }
      notifySurveyListDownloadError(error);
    });
  }
}
