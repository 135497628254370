import { connect } from 'react-redux';
import GroupSummary from '../Pages/GroupSummary/GroupSummary'
import actions from 'actions';
import { find } from 'lodash'

const mapStateToProps = (state, ownProps)=> {
  
  let company = state.company && state.company.company;
  let groupName = null;
  
  if(ownProps.match.params.group){
    groupName = ownProps.match.params.group.replaceAll('_', ' ');
  }
  let groups = company && company.groups;
  let group = {id: null, core: null, name: null};
  let user = state.current && state.current.user;
  
  if(groups){
    group = find(groups, (group) => group && (group.name === groupName)) || company.core_group;
  }
  let numPatients = state.patients && state.patients.patients && state.patients.patients.length;
  
  const updateSearchPreferences = (aSearchPreferences) => {
    user.search_preference = aSearchPreferences;
  };
  
  return {
    user:user,
    group:group,
    numPatients:numPatients,
    groups: groups,
    isLoading: state.isLoading,
    company: company,
    patients: state.patients && state.patients.patients,
    groupName: groupName,
    patientId: ownProps.match.params.id,
    pathname: ownProps.location.pathname,
    updateSearchPreferences: updateSearchPreferences
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addToolsToGroup: ({questIds, groupId}) => {
      return dispatch(actions.addToolsToGroup({questIds, groupId}))
    },
    fetchQuests: (aCompanyId, aType, aQuery, aGroupId, aLimit, aOffset) => {
      return dispatch(actions.fetchQuests(aCompanyId, aType, aQuery, aGroupId, aLimit, aOffset));
    },
    removeToolFromGroup: ({questId, groupId}) => {
      return dispatch(actions.removeToolFromGroup({questId, groupId}))
    },
    fetchCompanyAndPatients: ({groupName}) => {
      dispatch(actions.fetchCompanyAndPatients({groupName}))
    },
    fetchSymptoms:(aLimit, aOffset, aQuery, aUserId, aGroupId) => {
      return dispatch(actions.fetchSymptoms(aLimit, aOffset, aQuery, aUserId, aGroupId));
    },
    addSymptomToGroup: ({symptomId, groupId, extraNotes, customSymptom}) => {
      return dispatch(actions.addSymptomToGroup({symptomId, groupId, extraNotes, customSymptom}))
    },
    removeSymptomFromGroup: ({symptomId, groupId}) => {
      return dispatch(actions.removeSymptomFromGroup({symptomId, groupId}))
    },
    loadCompanyNotificationReceipts:(aSearchString, aUserId, aGroupId, aPage, aPerPage, aExcludeCleared, aSearchPreferences) => {
      return dispatch(actions.loadCompanyNotificationReceipts(aSearchString, aUserId, aGroupId, aPage, aPerPage, aExcludeCleared, aSearchPreferences))
    },
    clearCompanyNotificationReceipt: ({company_notification_receipt_id, note}) => {
      return dispatch(actions.clearCompanyNotificationReceipt({company_notification_receipt_id, note}))
    },
    updateFeatureToGroup:(aGroupId, aFeatureId, aEnabled) => {
      return dispatch(actions.updateFeatureToGroup(aGroupId, aFeatureId, aEnabled));
    },
    retryPayment:(aPayment) => {
      return dispatch(actions.retryPayment(aPayment));
    },
    fetchPayments:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchPayments(aPatientId, aGroupId, aLimit, aOffset, aSearchString));
    },
    createPayment: (aPatientID, aAmount, aCurrency) => {
      return dispatch(actions.createPayment(aPatientID, aAmount, aCurrency));
    },
    approvePayment: (payment) => {
      return dispatch(actions.approvePayment(payment));
    },
    removePayment:(payment) => {
      return dispatch(actions.removePayment(payment));
    },
    getVirtualAppointments:(aLimit, aOffset, aSearchString, aStartingBefore, aEndingAfter, aParticipantUserId, aGroupId, aOrderBy) => {
      return dispatch(actions.getVirtualAppointments( aLimit, aOffset, aSearchString, aStartingBefore, aEndingAfter, aParticipantUserId, aGroupId, aOrderBy));
    },
    saveVirtualAppointment:(virtualAppointment) => {
      return dispatch(actions.saveVirtualAppointment(virtualAppointment));
    },
    removeVirtualAppointment:({virtualAppointmentId}) => {
      return dispatch(actions.removeVirtualAppointment({virtualAppointmentId}));
    },
    fetchPatientsScroll: (aQuery, aGroupName, aLimit, aOffset, aOrderBy, aOrderByAscending, aPreferredLanguage, aAlertType, aVirtualAppointmentDate, aDispositionCode, aSearchByIdType, aSurveyIdForSorting, aQuestionKeyForSorting, aDynamicFields, aAnswerKeyForSorting, aIncludeAll, aGroupIdsToExclude) => {
      return dispatch(actions.fetchPatientsScroll(aQuery, aGroupName, aLimit, aOffset, aOrderBy, aOrderByAscending, aPreferredLanguage, aAlertType, aVirtualAppointmentDate, aDispositionCode, aSearchByIdType, aSurveyIdForSorting, aQuestionKeyForSorting, aDynamicFields, aAnswerKeyForSorting, aIncludeAll, aGroupIdsToExclude));
    },
    createDataQueryNote:(patientId, description, surveyResponseDetailId, notificationAlert) => {
      return dispatch(actions.createDataQueryNote(patientId, description, surveyResponseDetailId, notificationAlert))
    },
    createDataQueryComment:(dataQueryNoteId, commentText) => {
      return dispatch(actions.createDataQueryComment(dataQueryNoteId, commentText))
    },
    getDataQueryNote:(dataQueryNoteId) => {
      return dispatch(actions.getDataQueryNote(dataQueryNoteId))
    },
    getDataQueryNotes:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getDataQueryNotes(aPatientId, aGroupId, aLimit, aOffset, aSearchString));
    },
    getNotes:(patient_id, limit, offset) => {
      return dispatch(actions.getNotes(patient_id, limit, offset))
    },
    getDataQueryNoteComments:(aDataQueryNoteId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getDataQueryNoteComments(aDataQueryNoteId, aLimit, aOffset, aSearchString));
    },
    resolveDataQueryNote:(dataQueryNoteId) => {
      return dispatch(actions.resolveDataQueryNote(dataQueryNoteId))
    },
    getQuestionForSurveyResponseDetail:(surveyId, surveyResponseDetailId, patientId, aSurveyResponseId) => {
      return dispatch(actions.getQuestionForSurveyResponseDetail(surveyId, surveyResponseDetailId, patientId, aSurveyResponseId))
    },
    fetchFraudEvents:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchFraudEvents(aPatientId, aGroupId, aLimit, aOffset, aSearchString));
    },
    fetchFraudStatistics:(aPatientId) => {
      return dispatch(actions.fetchFraudStatistics(aPatientId));
    },
    markFraudEventAsVerified:(aFraudEventId, aComment) => {
      return dispatch(actions.markFraudEventAsVerified(aFraudEventId, aComment));
    },
    markFraudEventAsFraudulent:(aFraudEventId, aComment) => {
      return dispatch(actions.markFraudEventAsFraudulent(aFraudEventId, aComment));
    },
    markFraudStatisticAsVerified:(aFraudStatisticId, aComment) => {
      return dispatch(actions.markFraudStatisticAsVerified(aFraudStatisticId, aComment));
    },
    markFraudStatisticAsFraudulent:(aFraudStatisticId, aComment) => {
      return dispatch(actions.markFraudStatisticAsFraudulent(aFraudStatisticId, aComment));
    },
    loadCompanySymptoms:() => {
      return dispatch(actions.loadCompanySymptoms());
    },
    fetchCodesForGroup:(groupId, offset, limit, active) => {
      return dispatch(actions.fetchCodesForGroup(groupId, offset, limit, active));
    },
    createGroupCode:(groupId) => {
      return dispatch(actions.createGroupCode(groupId));
    },
    removeGroupCode:(aGroupCodeId) => {
      return dispatch(actions.removeGroupCode(aGroupCodeId));
    },
    downloadGroupCodeHistory:(aGroupId, aCompanyId) => {
      return dispatch(actions.downloadGroupCodeHistory(aGroupId, aCompanyId));
    },
    getQuestById:(aUserId, aQuestId) => {
      return dispatch(actions.getQuestById(aUserId, aQuestId));
    },
    getVideoForVirtualAppointment:(aVirtualAppointmentId) => {
      return dispatch(actions.getVideoForVirtualAppointment(aVirtualAppointmentId));
    },
    saveSchedule:(aScheduleId, aName, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove) => {
      return dispatch(actions.saveSchedule(aScheduleId, aName, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove));
    },
    fetchSchedule:(aScheduleId, aUserId) => {
      return dispatch(actions.fetchSchedule(aScheduleId, aUserId = null));
    },
    fetchSchedules:(aLimit, aOffset, aSearchString, aUserId, aGroupId, aOnlyRemoved) => {
      return dispatch(actions.fetchSchedules(aLimit, aOffset, aSearchString, aUserId, aGroupId, aOnlyRemoved));
    },
    removeSchedule:(aScheduleId) => {
      return dispatch(actions.removeSchedule(aScheduleId));
    },
    publishSchedule:(aScheduleId, aTriggerAddedToGroupEvents) => {
      return dispatch(actions.publishSchedule(aScheduleId, aTriggerAddedToGroupEvents));
    },
    saveScheduleTrigger:(aScheduleId, aScheduleTrigger) => {
      return dispatch(actions.saveScheduleTrigger(aScheduleId, aScheduleTrigger));
    },
    createPaymentTrigger:(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.createPaymentTrigger(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    fetchScheduleVersion:(aScheduleVersionId) => {
      return dispatch(actions.fetchScheduleVersion(aScheduleVersionId));
    },
    removePaymentTrigger:(aPaymentTriggerId) => {
      return dispatch(actions.removePaymentTrigger(aPaymentTriggerId));
    },
    updatePaymentTrigger:(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.updatePaymentTrigger(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    removeScheduleTrigger:(aScheduleId, aScheduleTriggerId) => {
      return dispatch(actions.removeScheduleTrigger(aScheduleId, aScheduleTriggerId));
    },
    fetchPatientsInSchedule:(aLimit, aOffset, aSearchString, aScheduleId) => {
      return dispatch(actions.fetchPatientsInSchedule(aLimit, aOffset, aSearchString, aScheduleId));
    },
    fetchQuestionsForSurveyById:(aSurveyId) => {
      return dispatch(actions.fetchQuestionsForSurveyById(aSurveyId));
    },
    fetchSurveyVersionCollections:(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField) => {
      return dispatch(actions.fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField));
    },
    fetchScheduleVersionsForSchedule:(aScheduleId, aLimit, aOffset) => {
      return dispatch(actions.fetchScheduleVersionsForSchedule(aScheduleId, aLimit, aOffset));
    },
    fetchQuestionsForSurveyByReference:(aReference) => {
      return dispatch(actions.fetchQuestionsForSurveyByReference(aReference));
    }
  }
};
export default connect(mapStateToProps,mapDispatchToProps)(GroupSummary)
