// Helper Methods

import i18next from "i18next";
import {AlertTypes} from "../constants/AlertTypes";

export function deleteEmptyProperties(aObject, aDeleteEmptyString = true){
  if(isObject(aObject)){
    for(let key of Object.keys(aObject)){
      if(aObject[key] === null || aObject[key] === undefined || (aDeleteEmptyString && aObject[key] === '')){
        delete aObject[key];
      }
      else if(isObject(aObject[key])){
        deleteEmptyProperties(aObject[key]);
      }
    }
  }
}

export function objectsAreEqual(aObject, aSecondObject){
  const objectKeys = Object.keys(aObject);
  const secondObjectKeys = Object.keys(aSecondObject);
  
  if(objectKeys.length !== secondObjectKeys.length){
    return false;
  }
  for(let key of objectKeys){
    if(aObject[key] !== aSecondObject[key]){
      if(typeof aObject[key] == "object" && typeof aSecondObject[key] == "object"){
        if(!objectsAreEqual(aObject[key], aSecondObject[key])){
          return false;
        }
      }
      else{
        return false;
      }
    }
  }
  return true;
}

export function keyValuePairsArrayForObject(aObject){
  let returnValue = [{key:'', value:''}];
  
  if(aObject){
    let keysArray = Object.keys(aObject);
    
    if(keysArray && keysArray.length > 0){
      returnValue = [];
      
      for(let key of keysArray){
        returnValue.push({key:key, value:aObject[key]});
      }
    }
  }
  return returnValue;
}

export function firstKeyValuePairForObject(aObject){
  let returnValue = {key:'', value:''};
  let keyValuePairsArray = keyValuePairsArrayForObject(aObject);
  
  if(keyValuePairsArray.length > 0){
    returnValue = keyValuePairsArray[0];
  }
  return returnValue;
}

export function snakeCaseToCapitalized(aString){
  let returnValue = '';
  
  if(aString){
    returnValue = aString.replaceAll('_', ' ');
    returnValue = returnValue.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase());
  }
  return returnValue;
}

export function camelToSnakeCase(aString){
  let returnValue = aString.replace(/[A-Z]/g, (aLetter) => `_${aLetter.toLowerCase()}`);
  
  if(aString && aString.length > 0){
    returnValue = aString.replace(/[A-Z]/g, (aLetter) => `_${aLetter.toLowerCase()}`);
    
    if(returnValue.substring(0, 1) === '_'){
      returnValue = returnValue.substring(1);
    }
  }
  return returnValue;
}

export function labelForAlertType(aAlertType){
  let returnValue = '';
  
  for(let alertType of AlertTypes){
    if(alertType.value === aAlertType){
      returnValue = alertType.text;
      break;
    }
  }
  return returnValue;
}

export function errorMessageFromServerError(aServerError){
  let returnValue = i18next.t('UNKNOWN_ERROR');
  
  if(aServerError && aServerError.data){
    if(aServerError.data.error){
      returnValue = aServerError.data.error;
    }
    else if(typeof aServerError.data === 'string'){
      returnValue = aServerError.data;
    }
  }
  return returnValue;
}

export function isObject(aPotentialObject){
  return (typeof aPotentialObject === "object" && !Array.isArray(aPotentialObject) && aPotentialObject !== null);
}

export function stringContainsNumber(aString){
  let returnValue = false;

  if(aString && (typeof aString === 'string' || aString instanceof String)){
    const regex = /^[\D]+$/;
    returnValue =  !regex.test(aString);
  }
  return returnValue;
}

export function stripHTML(aString){
  let returnValue = '';
  
  if(aString && (typeof aString === 'string' || aString instanceof String)){
    returnValue = aString.replace(/(<([^>]+)>)/ig, '');
  }
  return returnValue;
}

export function translatedOptionsArray(aObject){
  return Object.keys(aObject).map((aKey) => {return {label:i18next.t(aObject[aKey]), value:aKey}});
}

function addColumnsFromJsonToCSV(aJson, aKeysArray, aColumnsArray, aRowsArray, aIndicesForColumn){
  for(let key of Object.keys(aJson)){
    let joinedKey = '';
    
    for(let previousKey of aKeysArray){
      joinedKey += previousKey + '___'
    }
    joinedKey += key;
    let value = aJson[key];
    
    if(isObject(value)){
      aKeysArray.push(key);
      addColumnsFromJsonToCSV(value, aKeysArray, aColumnsArray, aRowsArray, aIndicesForColumn);
      aKeysArray.pop();
    }
    else if(Array.isArray(value)){
      for(let index = 0; index < value.length; index += 1){
        let item = value[index];
        
        if(isObject(item)){
          aKeysArray.push(key);
          addColumnsFromJsonToCSV(item, aKeysArray, aColumnsArray, aRowsArray, aIndicesForColumn);
          aKeysArray.pop();
        }
        else{
          if(!aColumnsArray.includes(joinedKey)){
            aColumnsArray.push(joinedKey);
            aIndicesForColumn[joinedKey] = 0;
          }
          else{
            aIndicesForColumn[joinedKey] += 1;
          }
          if(aIndicesForColumn[joinedKey] >= aRowsArray.length){
            let row = {};
            row[joinedKey] = item;
            aRowsArray.push(row);
          }
          else{
            aRowsArray[aIndicesForColumn[joinedKey]][joinedKey] = item;
          }
        }
      }
    }
    else{
      if(!aColumnsArray.includes(joinedKey)){
        aColumnsArray.push(joinedKey);
        aIndicesForColumn[joinedKey] = 0;
      }
      else{
        aIndicesForColumn[joinedKey] += 1;
      }
      if(aIndicesForColumn[joinedKey] >= aRowsArray.length){
        let row = {};
        row[joinedKey] = value;
        aRowsArray.push(row);
      }
      else{
        aRowsArray[aIndicesForColumn[joinedKey]][joinedKey] = value;
      }
    }
  }
}

export function jsonToCSV(aJson){
  let returnValue = '';
  
  if(isObject(aJson)){
    let rowsArray = [{}];
    let columnsArray = [];
    
    addColumnsFromJsonToCSV(aJson, [], columnsArray, rowsArray, {});
    
    for(let column of columnsArray){
      returnValue += column + ',';
    }
    returnValue = returnValue.substring(0, returnValue.length - 1);
    returnValue += '\n';
    
    for(let row of rowsArray){
      for(let column of columnsArray){
        let value = row[column];
        
        if(value && value.toString() && value.toString().length > 0){
          returnValue += '"' + value.toString().replaceAll('"', '""')  + '",';
        }
        else{
          returnValue += ',';
        }
      }
      returnValue = returnValue.substring(0, returnValue.length - 1);
      returnValue += '\n';
    }
  }
  return returnValue;
}

export function localDataURLtoBlob(aDataURL, aType = 'image/png'){
  let binary = atob(aDataURL.split(',')[1]);
  let array = [];
  
  for(let index = 0; index < binary.length; index++) {
    array.push(binary.charCodeAt(index));
  }
  return new Blob([new Uint8Array(array)], {type:aType});
}

export function dataURLtoResponseType(aDataURL, aResponseType, aSuccess, aFailure){
  const options = {withCredentials:false};

  if(aResponseType){
    options.responseType = aResponseType;
  }
  global.api.get(aDataURL, options).then((newResult) => aSuccess(newResult.data), (newError) => aFailure(newError));
}


export function downloadStringToFile(aString, aName, aExtension = '.txt'){
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(aString));
  element.setAttribute('download', aName + aExtension);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
