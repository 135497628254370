import {loadCompanyMinifiedGroups} from './company'
import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function updateGroup(aGroupId, aName, aLocale){
  EventTracker.track(EventTypes.UPDATE_GROUP);
  let query = `
  mutation UpdateGroup($updateGroupInput: UpdateGroupInput!) {
        updateGroup(input: $updateGroupInput) {
          group {
            id
            locale
            name
          }
        }
      }
  `;
  
  let operationName = `UpdateGroup`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['group_id'] = aGroupId;
    params['name'] = aName;
    params['locale'] = aLocale;
    
    let variables = {
      updateGroupInput:params
    };
    let promise = global.api.post("/queries", { query, variables, operationName });
    promise.then((result) => {
      dispatch(loadCompanyMinifiedGroups());
    }).catch((error) => {
    
    });
    return promise;
  };
}

export function addGroup(aName, aUserIds, aLocale){
  EventTracker.track(EventTypes.ADD_GROUP);
  let query = `
  mutation AddGroup($addGroupInput: AddGroupInput!) {
    addGroup(input: $addGroupInput) {
      group {
        id
        locale
        name
      }
    }
  }
  `;
  let variables = {
    addGroupInput: {name:aName}
  };
  let operationName = `AddGroup`;

  return (dispatch, getState) => {
    const { company } = getState();
    variables['addGroupInput']['company_id'] = company.company.id;
    
    if(aUserIds){
      variables['addGroupInput']['user_ids'] = aUserIds;
    }
    if(aLocale){
      variables['addGroupInput']['locale'] = aLocale;
    }
    return global.api.post("/queries",
      { query, variables, operationName }
    ).then((result) => {
      dispatch(loadCompanyMinifiedGroups());
      return result;
    }).catch((error) => {
      return error;
    });
  };
}

export function removeGroup(id){
  EventTracker.track(EventTypes.REMOVE_GROUP);
  let query = `
  mutation RemoveGroup($removeGroupInput: RemoveGroupInput!) {
    removeGroup(input: $removeGroupInput) {
      group {
        id
        locale
        name
      }
    }
  }
  `;
  let variables = {
    removeGroupInput: {id}
  };
  let operationName = `RemoveGroup`;
  
  return (dispatch) => {
    let promise = global.api.post("/queries", {query, variables, operationName});
    promise.then((newResult) => {
      dispatch(loadCompanyMinifiedGroups());
    }).catch((newError) => {
    
    });
    return promise;
  };
}

export function fetchQuests(aCompanyId, aType, aQuery, aGroupId, aLimit, aOffset){
  EventTracker.track(EventTypes.FETCH_QUESTS);
  let query = `
    query getQuests($query: String!, $limit: Int, $offset: Int, $group_id: ID) {
      quests_page(query: $query, scope: "public", company_id: ${aCompanyId}, type: "${aType}", limit: $limit, offset: $offset, group_id: $group_id) {
        quests{
          id
          title
          description
          creator_id
          parent_id
          root_quest_id
          quest_type
        }
        total
      }
    }
  `;
  let variables = {};
  
  return () => {
    variables['query'] = aQuery ? aQuery : '';
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['group_id'] = aGroupId;
    return global.api.post("/queries", {query, variables});
  };
}

export function addToolsToGroup({groupId, questIds}){
  EventTracker.track(EventTypes.ADD_TOOLS_TO_GROUP);
  let query = `
  mutation AddToolsToGroup($addToolsToGroupInput: AddToolsToGroupInput!) {
    addToolsToGroup(input: $addToolsToGroupInput) {
      group {
        id
        locale
        name
        quests {
          id
          title
        }
      }
    }
  }
  `;

  let variables = {
    addToolsToGroupInput: {
      group_id: groupId,
      quest_ids: questIds
    }
  };
  let operationName = `AddToolsToGroup`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadCompanyMinifiedGroups());
    }).catch((error) => {
    })
  }
}

export function removeToolFromGroup({questId, groupId}){
  EventTracker.track(EventTypes.REMOVE_TOOL_FROM_GROUP);
  let query = `
  mutation RemoveToolFromGroup($removeToolFromGroupInput: RemoveToolFromGroupInput!) {
    removeToolFromGroup (input: $removeToolFromGroupInput) {
      group {
        id
        locale
        name
        quests {
          id
          title
        }
      }
    }
  }
  `;

  let variables = {
    removeToolFromGroupInput: {
      group_id: groupId,
      quest_id: questId
    }
  };
  let operationName = `RemoveToolFromGroup`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadCompanyMinifiedGroups());
    })
  }
}

export function addObjectiveToTool({groupId, questId, name, type, reminders}){
  EventTracker.track(EventTypes.ADD_OBJECTIVE_TO_TOOL);
  let query = `
  mutation AddObjectiveToGroupQuest($addObjectiveToGroupQuestInput: AddObjectiveToGroupQuestInput!) {
    addObjectiveToGroupQuest (input: $addObjectiveToGroupQuestInput) {
      objective {
        id
        name
        group_quest_id
        type
        objective_group_quest_reminders {
          id
          note
          time
          repeat
          repeat_type
          day_number
        }
      }
    }
  }
  `;

  let variables = {
    addObjectiveToGroupQuestInput: {
      group_id: groupId,
      quest_id: questId,
      name,
      type,
      reminders
    }
  };
  let operationName = `AddObjectiveToGroupQuest`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadCompanyMinifiedGroups());
    }).catch((error) => {
    })
  }
}


export function removePointsFromObjective({objectivePointId}){
  EventTracker.track(EventTypes.REMOVE_POINTS_FROM_OBJECTIVE);
  let query = `
  mutation RemovePointsFromObjective($removePointsFromObjectiveInput: RemovePointsFromObjectiveInput!) {
    removePointsFromObjective (input: $removePointsFromObjectiveInput) {
      objective_point {
        id
        entry_value
        operator
        points
        objective_id
      }
    }
  }
  `;

  let variables = {
    removePointsFromObjectiveInput: {
      objective_point_id: objectivePointId
    }
  };
  let operationName = `RemovePointsFromObjective`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadCompanyMinifiedGroups());
    }).catch((error) => {
    })
  }
}

export function addPointsToObjective({objectiveId, entryValue, operator, points}){
  EventTracker.track(EventTypes.ADD_POINTS_TO_OBJECTIVE);
  let query = `
  mutation AddPointsToObjective($addPointsToObjectiveInput: AddPointsToObjectiveInput!) {
    addPointsToObjective (input: $addPointsToObjectiveInput) {
      objective_point {
        id
        entry_value
        operator
        points
        objective_id
      }
    }
  }
  `;

  let variables = {
    addPointsToObjectiveInput: {
      objective_id: objectiveId,
      entry_value: entryValue,
      operator,
      points
    }
  };
  let operationName = `AddPointsToObjective`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadCompanyMinifiedGroups());
    }).catch((error) => {
    })
  }
}

export function groupsWithCode(){
  let query = `
  query loadCompany {
    user {
      id
      companies {
        id
        core_group {
          id
          core
          name
          active_codes {
            id
            code
            valid_from
            valid_till
            users_count
          }
        }
        groups (group_type: "company") {
          id
          name
          core
          active_codes {
            id
            code
            valid_from
            valid_till
            users_count
          }
        }
      }
    }
  }
  `;
  let variables = {};

  return (dispatch) => {
      return global.api.post("/queries", {query, variables});
  }
}

export function fetchCodesForGroup(aGroupId, aOffset, aLimit, aAll = false){
  let graphQLQuery = `
    query fetchActiveCodes($company_id: ID!, $group_id: ID, $offset: Int, $limit: Int, $all: Boolean) {
      group_codes(company_id: $company_id, group_id: $group_id , offset: $offset, limit: $limit, all: $all) {
        group_codes {
          id
          code
          valid_from
          valid_till
          users_count
          created_at
          last_used
          group {
            id
            name
          }
        }
        total
      }
    }
  `;
  
  return (dispatch, getState) => {
    let state = getState();
    let variables = {limit: aLimit, offset: aOffset};
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['group_id'] = aGroupId;
    variables['offset'] = aOffset;
    variables['limit'] = aLimit;
    variables['all'] = aAll;
    return global.api.post("/queries", {query:graphQLQuery, variables});
  };
}

export function createGroupCode(groupId){
  let query = `
  mutation AddGroupCode($addGroupCodeInput: AddGroupCodeInput!) {
    addGroupCode (input: $addGroupCodeInput) {
      group_code {
        id
        code
        valid_from
        valid_till
        created_at
        last_used
      }
    }
  }
  `;

  return (dispatch, getState) => {
    let state = getState();
    let variables = {
      addGroupCodeInput: {
        group_id: groupId,
        company_id: state.company && state.company.company && state.company.company.id
      }
    };
    let operationName = `AddGroupCode`;
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function removeGroupCode(groupCodeId){
  let query = `
  mutation RemoveGroupCode($removeGroupCodeInput: RemoveGroupCodeInput!) {
    removeGroupCode (input: $removeGroupCodeInput) {
      group_code {
        id
      }
    }
  }
  `;

  return (dispatch, getState) => {
    
    let variables = {
      removeGroupCodeInput: {
        group_code_id: groupCodeId
      }
    };
    let operationName = `RemoveGroupCode`;
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function downloadGroupCodeHistory(aGroupId, aCompanyId){
  EventTracker.track(EventTypes.DOWNLOAD_GROUP_CODE_HISTORY);
  let url = '/api/tool_stats/group_code_usage_report';
  
  return () => {
    return global.api.post(url, {group_ids:[aGroupId], company_id:aCompanyId});
  }
}
