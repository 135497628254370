import React from 'react';
import { connect } from 'react-redux';
import actions from 'actions';
import Header from 'Components/Header/Header'
import Sidebar from 'Components/Sidebar/Sidebar'
import GroupSummaryContainer from './GroupSummaryContainer';
import PatientProfileContainer from './PatientProfileContainer';
import ManageGroupsContainer from './ManageGroupsContainer';
import ManagePatientsContainer from './ManagePatientsContainer';
import ManageUsersContainer from './ManageUsersContainer';
import ManageToolsContainer from './ManageToolsContainer';
import ManageSettingsContainer from './ManageSettingsContainer';
import ManageReportsContainer from './ManageReportsContainer';
import ManageCampaignsContainer from './ManageCampaignsContainer';
import SurveyLinksContainer from "./SurveyLinksContainer";
import { Switch, Route, Redirect } from 'react-router-dom'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.scss';
import { toast } from 'react-toastify';
import VirtualAppointmentsContainer from "./VirtualAppointmentsContainer";
import PaymentsContainer from "./PaymentsContainer";
import AdminHistoryContainer from "./AdminHistoryContainer";
import ArticlesContainer from "./ArticlesContainer";
import i18n from "../i18n";
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import TriggersContainer from "./TriggersContainer";
import {roleForCompany} from "../Helpers/CompanyHelpers";
import Loading from "../Custom UI/Loading/Loading";
import AccountDeactivated from "../Pages/AccountDeactivated/AccountDeactivated";
import ProtectedRoute from 'Components/ProtectedRoute/ProtectedRoute';
import CloseStudyMessage from 'Components/CloseStudyMessage/CloseStudyMessage';
import moment from "moment";
import './App.scss';
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import EventTracker from "../eventTracker";
import EventTypes from "../constants/EventTypes";
import ManageSchedulesContainer from "./ManageSchedulesContainer";

registerLocale('de', de);
registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('it', it);

toast.configure({draggable:false});

const expandedSidebarWidth = '205px';

export class App extends React.Component {
  
  // Initialize
  
  constructor(props){
    super(props);
    this.state = {
      width:0,
      height:0,
      loading:false,
      loadFailed:false,
      sidebarWidth:'0px',
      coreGroupReloadCount:0,
      showCloseStudyMessage:false
    };
    this.closeSidebar = this.closeSidebar.bind(this);
    this.toggleSidebarWidth = this.toggleSidebarWidth.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount(){
    const {company, loadUser, currentUser, loadCompany} = this.props;
    
    loadCompany();
    
    if(loadUser && (!currentUser || !currentUser.email)){
      loadUser();
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    setDefaultLocale(i18n.language);
    moment.locale(i18n.language);
    
    if(currentUser && currentUser.locale && ['de', 'en', 'es', 'fr', 'it'].includes(currentUser.locale)){
      i18n.changeLanguage(currentUser.locale).then((newResult) => {
        setDefaultLocale(currentUser.locale);
        moment.locale(currentUser.locale);
      });
    }
    this.reloadMinifiedCompanyDetails();
  }
  
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {company} = this.props;
    const {coreGroupReloadCount} = this.state;
    
    if(prevProps.company && company){
      if(prevProps.company.id !== company.id || !company.core_group){
        if(coreGroupReloadCount < 3){
          this.reloadMinifiedCompanyDetails();
          this.setState({coreGroupReloadCount:coreGroupReloadCount + 1});
        }
        else if(coreGroupReloadCount === 3){
          EventTracker.track(EventTypes.CORE_GROUP_MISSING);
          this.setState({coreGroupReloadCount:coreGroupReloadCount + 1});
        }
      }
      if(prevProps.company.status === 'open' && company.status === 'closed'){
        this.setState({showCloseStudyMessage:true});
      }
    }
  }
  
  // Methods
  
  reloadMinifiedCompanyDetails(){
    const {loadCompanyMinifiedGroups} = this.props;
    const {loading} = this.state;
    
    if(loadCompanyMinifiedGroups && loading === false){
      this.setState({loading:true});
      loadCompanyMinifiedGroups().then((newResult) => {
        this.setState({loading:false, loadFailed:false});
      }, (newError) => {
        this.setState({loading:false, loadFailed:true});
      });
    }
  }
  
  closeSidebar(aEvent){
    if((aEvent.pageX <= 50) && (aEvent.pageY <= 50)){
      aEvent.stopPropagation();
    }
    this.toggleSidebarWidth(aEvent);
  }
  
  toggleSidebarWidth(aEvent){
    aEvent.preventDefault();
    
    if(this.state.sidebarWidth === expandedSidebarWidth){
      this.setState({sidebarWidth:'0px'});
    }
    else{
      this.setState({sidebarWidth:expandedSidebarWidth});
    }
  }
  
  updateWindowDimensions(){
    this.setState({width:window.innerWidth, height:window.innerHeight});
  }
  
  SecuredRoute = (props) => {
    return (
      <Route path={props.path}
             render={data => this.props.permittedRoutes?.some(entry => `/manage/${entry.path}` === props?.location?.pathname) ?
               <props.component {...data}/>
               :
               <Redirect to={{ pathname: '/' }}/>
             }
      />
    )
  };
  
  // Render

  render() {
    const {width, sidebarWidth, showCloseStudyMessage} = this.state;
    const {logout, currentUser, company, groups, loadCompany, getNewArticlesCount, studyClosed} = this.props;
    
    let contentsWidth = 'calc(100vw - ' + sidebarWidth + ')';
    let sidebarContainerStyle = {};
    let sidebarHoverMode = false;
    const role = roleForCompany(company);
    
    if(width < 800){
      sidebarHoverMode = true;
      contentsWidth = '100vw';
      sidebarContainerStyle = {position:'fixed', top:0, left:0, width:sidebarWidth, height:'100%', zIndex:'9999', boxShadow:'0px 0px 5px 2px rgba(0,0,0,0.1)'};
    }
    
    return (
      <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
        {role.length === 0 ?
          <Loading className="app-loading"/>
          :
          (role === 'admin_level_66' ?
              <AccountDeactivated logout={logout}
                                  company={company}
              />
              :
              <>
                <Header company={company}
                        studyClosed={studyClosed}
                        sidebarExpanded={sidebarWidth === expandedSidebarWidth}
                        toggleSidebarWidth={this.toggleSidebarWidth}
                        getNewArticlesCount={getNewArticlesCount}
                />
                
                <div style={{display: 'flex', height: '100%'}}>
                  {sidebarWidth === expandedSidebarWidth ?
                    <div style={sidebarContainerStyle}>
                      <Sidebar groups={groups}
                               logout={logout}
                               company={company}
                               currentUser={currentUser}
                               loadCompany={loadCompany}
                               studyClosed={studyClosed}
                               closeSidebar={sidebarHoverMode ? (aEvent) => this.closeSidebar(aEvent) : null}
                               sidebarWidth={sidebarWidth}
                               sidebarExpanded={sidebarWidth === expandedSidebarWidth}
                      />
                    </div>
                    :
                    null
                  }
                  
                  <div style={{display:'block', height:'100%', backgroundColor:'#eceff1', marginTop:50, width:contentsWidth}}>
                    <div id='home' style={{maxHeight:'100vh',  paddingBottom:0, backgroundColor:'#eceff1', paddingLeft:'0'}}>
                      <Switch>
                        <ProtectedRoute exact path='/patients' component={GroupSummaryContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/patients/:id' component={PatientProfileContainer} studyClosed={studyClosed} />
                        <ProtectedRoute exact path='/groups/:group*/patients' component={GroupSummaryContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/groups/:group/patients/:id' component={PatientProfileContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/groups' component={ManageGroupsContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/patients' component={ManagePatientsContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/virtualAppointments' component={VirtualAppointmentsContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/admins' component={ManageUsersContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/admins/:id' component={ManageSettingsContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/tools' component={ManageToolsContainer} studyClosed={studyClosed} />
                        <Route path='/manage/reports' render={(props) => <ManageReportsContainer {...props} studyClosed={studyClosed} />}/>
                        <Route path='/manage/settings' component={ManageSettingsContainer} />
                        <Route path='/manage/papertrail' render={(props) => <AdminHistoryContainer {...props} studyClosed={studyClosed} showPatientSearch={true} />}/>
                        <ProtectedRoute path='/manage/payments' component={PaymentsContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/triggers' component={TriggersContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/articles' component={ArticlesContainer}/>
                        <ProtectedRoute path='/manage/campaigns' component={ManageCampaignsContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path="/manage/surveyLinks" component={SurveyLinksContainer} studyClosed={studyClosed} />
                        <ProtectedRoute path='/manage/schedules' component={ManageSchedulesContainer} studyClosed={studyClosed} />
                      </Switch>
                    </div>
                  </div>
                </div>
              </>
          )
        }

        {showCloseStudyMessage ?
          <CloseStudyMessage isOpen={showCloseStudyMessage}
                             logout={logout}
                             startTimer={true}
          />
          :
          null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    groups:state.company && state.company.company && state.company.company.groups,
    company:state.company && state.company.company,
    currentUser:state.current && state.current.user,
    studyClosed:state.company?.company?.status === 'closed',
    permittedRoutes:state.current && state.current.permittedItems
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout:() => {
      dispatch(actions.logout());
    },
    loadUser:() => {
      return dispatch(actions.loadUser());
    },
    loadCompany:() => {
      return dispatch(actions.loadCompany());
    },
    loadCompanyQuests:() => {
      return dispatch(actions.loadCompanyQuests());
    },
    getNewArticlesCount:(aUserId) => {
      return dispatch(actions.getNewArticlesCount(aUserId));
    },
    loadCompanySymptoms:() => {
      return dispatch(actions.loadCompanySymptoms());
    },
    loadCompanyMinifiedGroups:() => {
      return dispatch(actions.loadCompanyMinifiedGroups());
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
