import actions from 'actions';
import {connect} from 'react-redux';
import ManageTools from '../Pages/ManageTools/ManageTools';
import {createApolloClient} from "Helpers/ApolloClientHelpers";

const mapStateToProps = (state)=> {
  
  return {
    user:state.current && state.current.user,
    tools:state.tools && state.tools.tools,
    client:createApolloClient(),
    company:state.company && state.company.company,
    locales:state.locales && state.locales.locales
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTools:() => {
      dispatch(actions.fetchTools());
    },
    updateTool:({tool}) => {
      return dispatch(actions.updateTool({tool}));
    },
    fetchSurveyById:(aSurveyId) => {
      return dispatch(actions.fetchSurveyById(aSurveyId));
    },
    addToolToCompany:({tool}) => {
      return dispatch(actions.addToolToCompany({tool}));
    },
    removeToolFromCompany:({toolId}) => {
      return dispatch(actions.removeToolFromCompany({toolId}));
    },
    fetchLocales:() => {
      return dispatch(actions.fetchLocales());
    },
    fetchSurveyLists:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchSurveyLists(aLimit, aOffset, aSearchString));
    },
    fetchSurveyListById:(aId) => {
      return dispatch(actions.fetchSurveyListById(aId));
    },
    fetchSurveyListByReference:(aReference) => {
      return dispatch(actions.fetchSurveyListByReference(aReference));
    },
    fetchDynamicLists:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchDynamicLists(aLimit, aOffset, aSearchString));
    },
    fetchDynamicListById:(aId) => {
      return dispatch(actions.fetchDynamicListById(aId));
    },
    fetchDynamicListByReference:(aReference) => {
      return dispatch(actions.fetchDynamicListByReference(aReference));
    },
    validateRubularRegex:(aRegex) => {
      return dispatch(actions.validateRubularRegex(aRegex));
    },
    addSurveyList:(aFile, aName, aReference, aClient) => {
      return dispatch(actions.addSurveyList(aFile, aName, aReference, aClient));
    },
    updateSurveyList:(aSurveyListId, aFile, aName, aReference, aClient) => {
      return dispatch(actions.updateSurveyList(aSurveyListId, aFile, aName, aReference, aClient));
    },
    addQuestForSurveyVersionCollection:(aSurveyVersionCollectionId, aTitle, aHSCConsent, aOnboardingQuest) => {
      return dispatch(actions.addQuestForSurveyVersionCollection(aSurveyVersionCollectionId, aTitle, aHSCConsent, aOnboardingQuest));
    },
    fetchSurveyVersionCollectionById:(aId) => {
      return dispatch(actions.fetchSurveyVersionCollectionById(aId));
    },
    fetchAppAdditionalColumns:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchAppAdditionalColumns(aLimit, aOffset, aSearchString));
    },
    fetchSurveysForSurveyVersionCollection:(aId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchSurveysForSurveyVersionCollection(aId, aLimit, aOffset, aSearchString));
    },
    fetchSurveyVersionCollectionByReference:(aReference) => {
      return dispatch(actions.fetchSurveyVersionCollectionByReference(aReference));
    },
    fetchSurveyVersionCollections:(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField) => {
      return dispatch(actions.fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField));
    },
    fetchQuestionsForSurveyByReference:(aReference) => {
      return dispatch(actions.fetchQuestionsForSurveyByReference(aReference));
    },
    archiveSurveyVersionCollection:(aSurveyVersionCollectionId, aIsArchived) => {
      return dispatch(actions.archiveSurveyVersionCollection(aSurveyVersionCollectionId, aIsArchived));
    },
    publishSurveyVersionCollection:(aSurveyVersionCollectionId) => {
      return dispatch(actions.publishSurveyVersionCollection(aSurveyVersionCollectionId));
    },
    saveSurveyVersionCollection:(aSurveyId, aName, aYAML, aKeyword, aIsRepeatable, aAdminOnly, aTermsAndPrivacy, aIsChatbot) => {
      return dispatch(actions.saveSurveyVersionCollection(aSurveyId, aName, aYAML, aKeyword, aIsRepeatable, aAdminOnly, aTermsAndPrivacy, aIsChatbot));
    },
    updateToolSchedule:(aToolId, aTable, aColumn, aEndDate, aStartDate, aDuration, aFrequency, aDurationUnit, aFrequencyUnit) => {
      return dispatch(actions.updateToolSchedule(aToolId, aTable, aColumn, aEndDate, aStartDate, aDuration, aFrequency, aDurationUnit, aFrequencyUnit));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTools);
