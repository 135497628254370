import React, {useState, useRef, forwardRef} from 'react';
import './LabeledDelayedInput.scss';
import {DebounceInput} from "react-debounce-input";
import classNames from 'classnames';
import Loading from "../../Loading/Loading";
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import LabeledErrors from "../LabeledErrors/LabeledErrors";
import LabeledExtraButtons, {extraButtonsCount} from "../LabeledExtraButtons/LabeledExtraButtons";

const LabeledDelayedInput = forwardRef(({min, bold, type, name, label, value, onBlur, onClick, required, className, infoBlurb,
                               handleSave, placeholder, errorMessage, warningMessage, addButtonClicked, descriptionLabel,
                               popoverPlacement, editButtonClicked, menuButtonClicked, deleteButtonClicked,
                               loading = false, disabled = false, minLength = 2, showSearchIcon = false,
                               debounceTimeout = 1000, clearButtonClicked, preventLeadingSpaces = false, element="input"}, ref) => {
  
  // Instance Variables
  
  const inputRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [showInputCoverButton, setShowInputCoverButton] = useState(true);
  
  const focusInputClicked = () => {
    if(inputRef){
      inputRef.current.focus();
      
      if(onClick){
        onClick(inputRef.current);
      }
    }
    setShowInputCoverButton(false);
  };
  
  const inputBlurred = (aEvent) => {
    setShowInputCoverButton(true);
    setIsFocused(false);
    
    if(onBlur){
      onBlur(aEvent);
    }
  };
  
  const inputFocused = () => {
    setIsFocused(true);
  };

  const buttonsCount = extraButtonsCount(showSearchIcon, addButtonClicked, editButtonClicked, deleteButtonClicked);
  
  // Render

  return (
    <div className={'labeled-delayed-input-container ' + className}>
      <LabeledInfo bold={bold}
                   label={label}
                   required={required}
                   infoBlurb={infoBlurb}
                   description={descriptionLabel}
                   popoverPlacement={popoverPlacement}
                   menuButtonClicked={menuButtonClicked}
      />
      
      <div className="labeled-input-search-container">
        <div className="labeled-input-search-button-input">
          {showInputCoverButton ?
            <button style={{position:'absolute', height:'39px', left:0, top:0, opacity:0, width: '100%'}}
                    onClick={focusInputClicked}>
            </button>
            :
            null
          }
  
          <DebounceInput className={classNames({
            'labeled-delayed-input-textarea':element === 'textarea',
            'is-empty':!(inputRef && inputRef.current && inputRef.current.value),
            'is-focused':isFocused,
            'text-input-field-inactive':disabled,
            'labeled-delayed-input-error':errorMessage,
            'labeled-delayed-input-warning':warningMessage
          })}
                         min={min ?  min : null}
                         name={name}
                         type={type}
                         value={value ? value : ''}
                         onBlur={inputBlurred}
                         element={element}
                         onFocus={inputFocused}
                         disabled={disabled}
                         inputRef={(aElement) => {
                          inputRef.current = aElement;

                          if(ref){
                            ref.current = aElement;
                          }
                         }}
                         onKeyDown={(aEvent) => {
                           if(preventLeadingSpaces && (!aEvent.target.value && aEvent.key === ' ')) {
                             aEvent.preventDefault();
                           }
                         }}
                         onChange={(aEvent) => {
                           // aEvent.preventDefault();
                           // aEvent.stopPropagation();
                           // TODO: Use onKeyUp? Maybe on parent?
                           handleSave(aEvent);
                         }}
                         minLength={minLength}
                         placeholder={placeholder}
                         debounceTimeout={debounceTimeout}
          />
        </div>
        
        <Loading className="labeled-delayed-input-loading"
                 style={{right:(buttonsCount * 35 + 15).toString() +'px'}}
                 size={'mini'}
                 loading={loading}
                 showLabel={false}
        />

        {!loading && clearButtonClicked ?
          <button className="labeled-delayed-input-clear"
                  style={{right:(buttonsCount * 35).toString() +'px'}}
                  onClick={clearButtonClicked}
          />
          : null
        }
  
        <LabeledExtraButtons showSearchIcon={showSearchIcon}
                             addButtonClicked={addButtonClicked}
                             editButtonClicked={editButtonClicked}
                             deleteButtonClicked={deleteButtonClicked}
        />
      </div>
      
      <LabeledErrors errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  )
});
export default LabeledDelayedInput;
