import React from 'react';
import './ScheduleTrigger.scss';
import Add from "../../Custom UI/Add/Add";
import Menu from "@material-ui/core/Menu";
import {toast} from "react-toastify";
import i18next from "i18next";
import MenuItem from "@material-ui/core/MenuItem";
import RedButton from "../../Custom UI/Buttons/RedButton/RedButton";
import Separator from "../../Custom UI/Separator/Separator";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import LanguagePicker from "../Tools/CreateTool/LanguagePicker/LanguagePicker";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {KeyForTranslation} from "../../Helpers/SurveyBuilderHelpers";
import ScheduleConditionCell from "../../Cells/ScheduleConditionCell/ScheduleConditionCell";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import {ScheduleTriggerTypes, ScheduleTriggerEventTypeValues, scheduleTriggerEventOptionsArray, ScheduleTriggerConditionEventTypes,
  ScheduleTriggerFireEventTypeValues, scheduleTriggerOffsetUnitOptionsArray, scheduleTriggerFireEventTypesOptionsArray,
  scheduleTriggerConditionEventTypesOptionsArray, scheduleTriggerTimeBasedEventTypesOptionsArray} from "../../constants/ScheduleTriggerTypes";
import ScheduleTriggerExtras from "../ScheduleTriggerExtras/ScheduleTriggerExtras";
import {emailIsValid} from "../../Helpers/AdminHelpers";

const notifyScheduleTriggerSaveError = (aError) => toast(i18next.t('SCHEDULE_TRIGGER_SAVE_ERROR', {aError:aError}), NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyScheduleTriggerSaveSuccess = () => toast(i18next.t('SCHEDULE_TRIGGER_SAVED'), NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyScheduleTriggerRemovedError = (aError) => toast(i18next.t('SCHEDULE_TRIGGER_REMOVED_ERROR', {aError:aError}), NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyScheduleTriggerRemovedSuccess = () => toast(i18next.t('SCHEDULE_TRIGGER_REMOVED'), NOTIFY_OPTS.autoCloseThreeSeconds);

export default class ScheduleTrigger extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      saving:false,
      surveys:{},
      surveyQuestions:{},
      menuAnchorElement:null,
      confirmRemoveModalOpen:false,
      selectedConditionIndex:-1,
      trigger:{
        id:props.trigger ? props.trigger.id : null,
        fire_event:props.trigger ? props.trigger.fire_event : '',
        fire_extras:{
          offset:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.offset : '',
          offset_unit:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.offset_unit : '',
          specific_date:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.specific_date : '',
          admin_alert_emails:props.trigger && props.trigger.fire_extras && props.trigger.fire_extras.admin_alert_emails ? props.trigger.fire_extras.admin_alert_emails : [],
          admin_alert_message:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.admin_alert_message : '',
          admin_alert_message_ca:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.admin_alert_message_ca : '',
          admin_alert_message_de:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.admin_alert_message_de : '',
          "admin_alert_message_en-GB":props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras['admin_alert_message_en-GB'] : '',
          admin_alert_message_es:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.admin_alert_message_es : '',
          "admin_alert_message_es-EU":props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras['admin_alert_message_es-EU'] : '',
          admin_alert_message_fi:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.admin_alert_message_fi : '',
          admin_alert_message_fr:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.admin_alert_message_fr : '',
          "admin_alert_message_fr-EU":props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras['admin_alert_message_fr-EU'] : '',
          admin_alert_message_it:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.admin_alert_message_it : '',
          reference_date_type:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.reference_date_type : '',
          reference_date_value:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.reference_date_value : '',
          patient_notification_message:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.patient_notification_message : '',
          patient_notification_message_ca:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.patient_notification_message_ca : '',
          patient_notification_message_de:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.patient_notification_message_de : '',
          "patient_notification_message_en-GB":props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras['patient_notification_message_en-GB'] : '',
          patient_notification_message_es:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.patient_notification_message_es : '',
          "patient_notification_message_es-EU":props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras['patient_notification_message_es-EU'] : '',
          patient_notification_message_fi:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.patient_notification_message_fi : '',
          patient_notification_message_fr:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.patient_notification_message_fr : '',
          "patient_notification_message_fr-EU":props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras['patient_notification_message_fr-EU'] : '',
          patient_notification_message_it:props.trigger && props.trigger.fire_extras ? props.trigger.fire_extras.patient_notification_message_it : '',
          reference_date_survey_version_collection_id:props.trigger && props.trigger.fire_extras_reference_date_survey_version_collection ? props.trigger.fire_extras_reference_date_survey_version_collection.reference : '',
        },
        fire_survey_version_collection_id:props.trigger && props.trigger.fire_survey_version_collection ? props.trigger.fire_survey_version_collection.reference : '',
        is_time_based:props.trigger ? props.trigger.is_time_based : false,
        time_based_event:props.trigger ? props.trigger.time_based_event : '',
        time_based_extras:{
          offset:props.trigger && props.trigger.time_based_extras ? props.trigger.time_based_extras.offset : '',
          max_offset:props.trigger && props.trigger.time_based_extras ? props.trigger.time_based_extras.max_offset : '',
          offset_unit:props.trigger && props.trigger.time_based_extras ? props.trigger.time_based_extras.offset_unit : '',
          max_offset_unit:props.trigger && props.trigger.time_based_extras ? props.trigger.time_based_extras.max_offset_unit : ''
        },
        time_based_additional_app_column_id:props.trigger && props.trigger.time_based_additional_app_column && props.trigger.time_based_additional_app_column.id ? props.trigger.time_based_additional_app_column.id : '',
        time_based_survey_version_collection_id:props.trigger && props.trigger.time_based_survey_version_collection && props.trigger.time_based_survey_version_collection.reference ? props.trigger.time_based_survey_version_collection.reference : '',
        condition_event:props.trigger ? props.trigger.condition_event : '',
        condition_extras:{
          offset:props.trigger && props.trigger.condition_extras ? props.trigger.condition_extras.offset : '',
          offset_unit:props.trigger && props.trigger.condition_extras ? props.trigger.condition_extras.offset_unit : '',
          specific_date:props.trigger && props.trigger.condition_extras ? props.trigger.condition_extras.specific_date : '',
          question_conditions:props.trigger && props.trigger.condition_extras ? props.trigger.condition_extras.question_conditions : [],
          reference_date_type:props.trigger && props.trigger.condition_extras ? props.trigger.condition_extras.reference_date_type : '',
          reference_date_value:props.trigger && props.trigger.condition_extras ? props.trigger.condition_extras.reference_date_value : '',
          reference_date_survey_version_collection_id:props.trigger && props.trigger.condition_extras_reference_date_survey_version_collection ? props.trigger.condition_extras_reference_date_survey_version_collection.reference : '',
        },
        condition_survey_version_collection_id:props.trigger && props.trigger.condition_survey_version_collection ? props.trigger.condition_survey_version_collection.reference : ''
      },
      selectedTranslation:{
        title:'English',
        locale:'en',
        display_name:'English (US)'
      },
      translationsArray:[{
        title:'English',
        locale:'en',
        display_name:'English (US)'
      }]
    };
    if(props.trigger && props.trigger.fire_extras && props.trigger.fire_extras.admin_alert_emails && (typeof props.trigger.fire_extras.admin_alert_emails === 'string')){
      this.state.trigger.fire_extras.admin_alert_emails = this.state.trigger.fire_extras.admin_alert_emails.split(',');
    }
    if(props.triggerType !== ScheduleTriggerTypes.general){
      this.state.trigger.fire_event = props.triggerType === ScheduleTriggerTypes.start ? 'set_survey_start_date' : 'set_survey_end_date'

      if(props.surveyId){
        this.state.trigger.fire_survey_version_collection_id = props.surveyId;
      }
    }
    else if(props.surveyId){
      if(this.state.trigger.is_time_based){
        this.state.trigger.time_based_survey_version_collection_id = props.surveyId;
      }
      else{
        this.state.trigger.condition_survey_version_collection_id = props.surveyId;
      }
    }
    // TODO: Should be detected if the locales change, instead of just on init.
    if(props.languages && props.trigger && props.trigger.fire_extras){
      for(let translation of props.languages){
        if(translation.locale !== 'en'){
          const adminNotificationMessageKey = KeyForTranslation('admin_alert_message', translation);
          const patientNotificationMessageKey = KeyForTranslation('patient_notification_message', translation);
          const fireExtras = props.trigger.fire_extras;

          if(fireExtras[adminNotificationMessageKey] && fireExtras[adminNotificationMessageKey].length > 0){
            this.state.trigger.fire_extras[adminNotificationMessageKey] = fireExtras[adminNotificationMessageKey];
          }
          else{
            this.state.trigger.fire_extras[adminNotificationMessageKey] = '';
          }
          if(fireExtras[patientNotificationMessageKey] && fireExtras[patientNotificationMessageKey].length > 0){
            this.state.trigger.fire_extras[patientNotificationMessageKey] = fireExtras[patientNotificationMessageKey];
          }
          else{
            this.state.trigger.fire_extras[patientNotificationMessageKey] = '';
          }
        }
      }
    }
  }

  componentDidMount(){
    const {surveyId, languages} = this.props;
    const {trigger, translationsArray} = this.state;

    if(surveyId){
      this.fetchQuestionForSurveyId(surveyId);
    }
    if(trigger){
      if(trigger.is_time_based && trigger.time_based_survey_version_collection_id && trigger.time_based_survey_version_collection_id.length > 0){
        this.fetchQuestionForSurveyId(trigger.time_based_survey_version_collection_id);
      }
      else if(!trigger.is_time_based && trigger.condition_survey_version_collection_id && trigger.condition_survey_version_collection_id.length > 0){
        this.fetchQuestionForSurveyId(trigger.condition_survey_version_collection_id);
      }
      if(trigger.fire_extras.reference_date_type === 'survey_question' &&
        trigger.fire_extras.reference_date_survey_version_collection_id &&
        trigger.fire_extras.reference_date_survey_version_collection_id.length > 0){
        this.fetchQuestionForSurveyId(trigger.fire_extras.reference_date_survey_version_collection_id, true);
      }
      if(trigger.condition_extras.reference_date_type === 'survey_question' &&
        trigger.condition_extras.reference_date_survey_version_collection_id &&
        trigger.condition_extras.reference_date_survey_version_collection_id.length > 0){
        this.fetchQuestionForSurveyId(trigger.condition_extras.reference_date_survey_version_collection_id, true);
      }
      if(trigger.condition_extras && trigger.condition_extras.question_conditions && trigger.condition_extras.question_conditions.length > 0){
        for(let condition of trigger.condition_extras.question_conditions){
          if(condition.survey_version_collection_id && condition.survey_version_collection_id.length > 0){
            this.fetchQuestionForSurveyId(condition.survey_version_collection_id, true);
          }
        }
      }
    }
    if(languages){
      for(let translation of languages){
        if(translation.locale !== 'en'){
          const adminNotificationMessageKey = KeyForTranslation('admin_alert_message', translation);
          const patientNotificationMessageKey = KeyForTranslation('patient_notification_message', translation);
          const fireExtras = this.state.trigger.fire_extras;

          if((fireExtras[adminNotificationMessageKey] && fireExtras[adminNotificationMessageKey].length > 0) ||
            (fireExtras[patientNotificationMessageKey] && fireExtras[patientNotificationMessageKey].length > 0)){
            translationsArray.push(translation);
          }
        }
      }
      this.setState({translationsArray:translationsArray});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {save, finishedSaving, saveButtonPressed} = this.props;
    const {saving, trigger} = this.state;

    if(this.adminEmailsAreValid()){
      if(!prevProps.saveButtonPressed && saveButtonPressed && save && !saving){
        this.setState({saving:true}, () => {
          save(trigger).then((newResult) => {
            notifyScheduleTriggerSaveSuccess();
            this.setState({saving:false}, () => finishedSaving(true));
          }, (newError) => {
            notifyScheduleTriggerSaveError(errorMessageFromServerError(newError));
            this.setState({saving:false}, () => finishedSaving(false));
          });
        });
      }
    }
    finishedSaving(false)
  }

  // Methods

  fetchQuestionForSurveyId = (aSurveyKey, aFetchSurvey = false) => {
    const {fetchSurveyVersionCollections, fetchQuestionsForSurveyByReference} = this.props;
    const {surveys, surveyQuestions} = this.state;

    if(aSurveyKey && aSurveyKey.length > 0){
      if(fetchQuestionsForSurveyByReference && (surveys[aSurveyKey] === undefined || surveys[aSurveyKey] === null)){
        fetchSurveyVersionCollections(5, 0, aSurveyKey, false, false, 'reference').then((newResult) => {
          if(newResult.data.data.survey_version_collections.survey_version_collections && newResult.data.data.survey_version_collections.survey_version_collections.length > 0){
            let newSurveys = {...this.state.surveys};
            newSurveys[aSurveyKey] = newResult.data.data.survey_version_collections.survey_version_collections[0];
            this.setState({surveys:newSurveys});
          }
        });
      }
      if(fetchQuestionsForSurveyByReference && (surveyQuestions[aSurveyKey] === undefined || surveyQuestions[aSurveyKey] === null)){
        fetchQuestionsForSurveyByReference(aSurveyKey).then((newResult) => {
          let questions = {...this.state.surveyQuestions};
          questions[aSurveyKey] = newResult.data.data.survey_questions_for_survey_version_collection;
          this.setState({surveyQuestions:questions});
        }, (newError) => {

        });
      }
    }
  }

  addOrRemoveTranslation(aTranslation){
    let {translationsArray, selectedTranslation} = this.state;

    if(aTranslation.locale !== 'en'){
      if(translationsArray && translationsArray.length > 0){
        let indexToRemove = -1;

        for(let index = 0; index < translationsArray.length; index += 1){
          let translation = translationsArray[index];

          if(translation.locale === aTranslation.locale){
            indexToRemove = index;
            break;
          }
        }
        if(indexToRemove === -1){
          translationsArray.push(aTranslation);
        }
        else{
          if(selectedTranslation.locale === aTranslation.locale){
            for(let translation of this.props.languages){
              if(translation.locale === 'en'){
                selectedTranslation = translation;
                break;
              }
            }
          }
          translationsArray.splice(indexToRemove, 1);
        }
        this.setState({translationsArray:translationsArray, selectedTranslation:selectedTranslation});
      }
    }
  }

  questionsForSurveyId = (aSurveyId) => {
    const {trigger, surveyQuestions} = this.state;

    let returnValue = [];
    let selectedSurvey = trigger.is_time_based ? trigger.time_based_survey_version_collection_id : trigger.condition_survey_version_collection_id;

    if(aSurveyId && aSurveyId.length > 0){
      selectedSurvey = aSurveyId;
    }
    if(surveyQuestions[selectedSurvey]){
      for(let question of surveyQuestions[selectedSurvey]){
        returnValue.push({label:question.key + ' - (' + question.type + ')', value:question.key});
      }
    }
    return returnValue;
  };

  adminEmailsAreValid = () => {
    const {trigger} = this.state;
    let returnValue = true;

    if(trigger && trigger.fire_extras && Array.isArray(trigger.fire_extras.admin_alert_emails)){
      for(let email of trigger.fire_extras.admin_alert_emails){
        if(email && email.length > 0 && !emailIsValid(email)){
          returnValue = false;
          break;
        }
      }
    }
    return returnValue;
  };

  // Render

  render(){
    const {surveyId, company, languages, triggerType, surveysArray, finishedSaving, removeScheduleTrigger, fetchAppAdditionalColumns,
      fetchSurveyVersionCollections} = this.props;
    const {trigger, saving, surveys, surveyQuestions, menuAnchorElement, confirmRemoveModalOpen, selectedConditionIndex,
      translationsArray, selectedTranslation} = this.state;

    const role = roleForCompany(company);
    const editProhibitions = ['admin_level_1', 'admin_level_10'];

    const disabled = this.props.disabled || saving;
    const isSettingTrigger = triggerType !== ScheduleTriggerTypes.general && surveyId !== undefined;

    let surveyOptionsArray = [];
    let fireSurveyOptionsArray = [];

    for(let survey of surveysArray){
      if(isSettingTrigger && survey.reference === surveyId){
        fireSurveyOptionsArray.push({label:survey.name, value:survey.reference});
        break;
      }
      surveyOptionsArray.push({label:survey.name, value:survey.reference});
      fireSurveyOptionsArray.push({label:survey.name, value:survey.reference});
    }

    const selectedSurvey = trigger.is_time_based ? trigger.time_based_survey_version_collection_id : trigger.condition_survey_version_collection_id;

    const eventType = trigger.is_time_based ? trigger.time_based_event : trigger.condition_event;
    let eventTypeOptionsArray = trigger.is_time_based ? scheduleTriggerTimeBasedEventTypesOptionsArray() : scheduleTriggerConditionEventTypesOptionsArray();

    if(surveyOptionsArray.length === 0){
      eventTypeOptionsArray = [{label:i18next.t(ScheduleTriggerConditionEventTypes.added_to_group), value:'added_to_group'}];
    }

    const adminNotificationMessageKey = KeyForTranslation('admin_alert_message', selectedTranslation);
    const patientNotificationMessageKey = KeyForTranslation('patient_notification_message', selectedTranslation);

    return (
      <div className="schedule-trigger-container">
        <div className="schedule-trigger-cell">
          <LabeledDropDown className="schedule-trigger-dropdown-small"
                           label={i18next.t('TRIGGER_EVENT_TYPE')}
                           value={scheduleTriggerEventOptionsArray().filter(option => {
                             return option.value === (trigger.is_time_based ? ScheduleTriggerEventTypeValues.is_time_based : ScheduleTriggerEventTypeValues.is_condition_based);
                           })}
                           options={scheduleTriggerEventOptionsArray()}
                           disabled={disabled || (surveyOptionsArray.length === 0)}
                           infoBlurb={i18next.t('TRIGGER_EVENT_TYPE_EXPLANATION')}
                           handleSave={(aOption) => {
                             if(trigger.is_time_based && aOption.value === ScheduleTriggerEventTypeValues.is_condition_based){
                               trigger.condition_survey_version_collection_id = trigger.time_based_survey_version_collection_id;
                               trigger.time_based_survey_version_collection_id = '';
                             }
                             else if(!trigger.is_time_based && aOption.value === ScheduleTriggerEventTypeValues.is_time_based){
                               trigger.time_based_survey_version_collection_id = trigger.condition_survey_version_collection_id;
                               trigger.condition_survey_version_collection_id = '';
                             }
                             trigger.is_time_based = aOption.value === ScheduleTriggerEventTypeValues.is_time_based;
                             this.setState({trigger:{...trigger}});
                           }}
                           placeholder={i18next.t('TRIGGER_EVENT_TYPE')}
          />

          <LabeledDropDown className="schedule-trigger-dropdown-small"
                           label={i18next.t(trigger.is_time_based ? 'TIME_BASED_SURVEY' : 'CONDITION_BASED_SURVEY')}
                           value={surveyOptionsArray.filter(option => {
                             return option.value === selectedSurvey;
                           })}
                           options={surveyOptionsArray}
                           disabled={disabled || (eventType === 'added_to_group')}
                           required={true}
                           infoBlurb={i18next.t(trigger.is_time_based ? 'TIME_BASED_SURVEY_EXPLANATION' : 'CONDITION_BASED_SURVEY_EXPLANATION')}
                           handleSave={(aOption) => {
                             if(trigger.is_time_based){
                               trigger.condition_survey_version_collection_id = '';
                               trigger.time_based_survey_version_collection_id = aOption.value;
                             }
                             else{
                               trigger.condition_survey_version_collection_id = aOption.value;
                               trigger.time_based_survey_version_collection_id = '';
                             }
                             this.setState({trigger:{...trigger}}, () => this.fetchQuestionForSurveyId(aOption.value));
                           }}
                           placeholder={i18next.t('CHOOSE_SURVEY')}
          />

          <LabeledDropDown className="schedule-trigger-dropdown-small"
                           label={i18next.t(trigger.is_time_based ? 'TIME_BASED_EVENT_TYPE' : 'CONDITION_BASED_EVENT_TYPE')}
                           value={eventTypeOptionsArray.filter(option => {
                             return option.value === eventType;
                           })}
                           options={eventTypeOptionsArray}
                           disabled={disabled}
                           required={true}
                           infoBlurb={i18next.t(trigger.is_time_based ? 'TIME_BASED_EVENT_TYPE_EXPLANATION' : 'CONDITION_BASED_EVENT_TYPE_EXPLANATION')}
                           handleSave={(aOption) => {
                             if(trigger.is_time_based){
                               trigger.condition_event = '';
                               trigger.time_based_event = aOption.value;
                             }
                             else{
                               trigger.condition_event = aOption.value;
                               trigger.time_based_event = '';
                             }
                             this.setState({trigger:{...trigger}});
                           }}
                           placeholder={i18next.t(trigger.is_time_based ? 'TIME_BASED_EVENT_TYPE' : 'CONDITION_BASED_EVENT_TYPE')}
          />
        </div>

        {trigger.is_time_based && trigger.time_based_event === 'variable_set' ?
          <div className="schedule-trigger-cell">
            <LabeledDropDown className="schedule-trigger-dropdown"
                             label={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_VALUE')}
                             value={trigger.time_based_additional_app_column_id}
                             infoBlurb={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_VALUE_ADDITIONAL_COLUMN_EXPLANATION')}
                             handleSave={(aOption) => {
                               trigger.time_based_additional_app_column_id = aOption.name;
                               this.setState({trigger:{...trigger}});
                             }}
                             isClearable={true}
                             fetchObjects={(aLimit, aOffset, aSearchString) => fetchAppAdditionalColumns(aLimit, aOffset, aSearchString)}
                             saveWithOptionsKey={true}
                             optionsArrayFormatter={(aArray) => {
                               let returnValue = [];
                               let addCurrentKeyToOptions = true;

                               for(let additionalAppColumn of aArray){
                                 if(trigger.time_based_additional_app_column_id === additionalAppColumn.name){
                                   addCurrentKeyToOptions = false;
                                 }
                                 returnValue.push({
                                   value:additionalAppColumn.name,
                                   label:additionalAppColumn.name + ' - (' + additionalAppColumn.name_type + ')',
                                   type:additionalAppColumn.name_type,
                                   id:additionalAppColumn.id
                                 });
                               }
                               if(addCurrentKeyToOptions && trigger.time_based_additional_app_column_id !== null && trigger.time_based_additional_app_column_id !== undefined){
                                 returnValue.push({
                                   value:trigger.time_based_additional_app_column_id,
                                   label:trigger.time_based_additional_app_column_id,
                                   type:''
                                 });
                               }
                               return returnValue;
                             }}
                             responseTotalKeysArray={['data', 'data', 'additional_app_columns', 'total']}
                             responseObjectKeysArray={['data', 'data', 'additional_app_columns', 'additional_app_columns']}
            />
          </div>
          :
          null
        }

        {trigger.is_time_based ?
          <div>
            <div className="schedule-trigger-cell-inner">
              <LabeledTextInput className="schedule-trigger-input"
                                label={i18next.t('TIME_BASED_TRIGGER_OFFSET')}
                                value={trigger.time_based_extras.offset}
                                disabled={disabled}
                                infoBlurb={i18next.t('TIME_BASED_TRIGGER_OFFSET_EXPLANATION')}
                                handleSave={(aEvent) => {
                                  trigger.time_based_extras.offset = aEvent.target.value;
                                  this.setState({trigger:{...trigger}});
                                }}
                                placeholder={i18next.t('TIME_BASED_TRIGGER_OFFSET')}
              />

              <LabeledDropDown className="schedule-trigger-dropdown"
                               label={i18next.t('TIME_BASED_TRIGGER_OFFSET_UNIT')}
                               value={scheduleTriggerOffsetUnitOptionsArray().filter(option => {
                                 return option.value === trigger.time_based_extras.offset_unit;
                               })}
                               options={scheduleTriggerOffsetUnitOptionsArray()}
                               disabled={disabled}
                               infoBlurb={i18next.t('TIME_BASED_TRIGGER_OFFSET_UNIT_EXPLANATION')}
                               handleSave={(aOption) => {
                                 trigger.time_based_extras.offset_unit = aOption.value;
                                 this.setState({trigger:{...trigger}});
                               }}
                               isClearable={true}
                               placeholder={i18next.t('TIME_BASED_TRIGGER_OFFSET_UNIT')}
              />
            </div>

            <div className="schedule-trigger-cell-inner">
              <LabeledTextInput className="schedule-trigger-input"
                                label={i18next.t('TIME_BASED_TRIGGER_MAX_OFFSET')}
                                value={trigger.time_based_extras.max_offset}
                                disabled={disabled}
                                infoBlurb={i18next.t('TIME_BASED_TRIGGER_MAX_OFFSET_EXPLANATION')}
                                handleSave={(aEvent) => {
                                  trigger.time_based_extras.max_offset = aEvent.target.value;
                                  this.setState({trigger:{...trigger}});
                                }}
                                placeholder={i18next.t('TIME_BASED_TRIGGER_MAX_OFFSET')}
              />

              <LabeledDropDown className="schedule-trigger-dropdown"
                               label={i18next.t('TIME_BASED_TRIGGER_MAX_OFFSET_UNIT')}
                               value={scheduleTriggerOffsetUnitOptionsArray().filter(option => {
                                 return option.value === trigger.time_based_extras.max_offset_unit;
                               })}
                               options={scheduleTriggerOffsetUnitOptionsArray()}
                               disabled={disabled}
                               infoBlurb={i18next.t('TIME_BASED_TRIGGER_MAX_OFFSET_UNIT_EXPLANATION')}
                               handleSave={(aOption) => {
                                 trigger.time_based_extras.max_offset_unit = aOption.value;
                                 this.setState({trigger:{...trigger}});
                               }}
                               isClearable={true}
                               placeholder={i18next.t('TIME_BASED_TRIGGER_MAX_OFFSET_UNIT')}
              />
            </div>
          </div>
          :
          null
        }

        <ScheduleTriggerExtras save={(aTriggerExtras) => {
          trigger.condition_extras = aTriggerExtras;
          this.setState({trigger:{...trigger}});
        }}
                               surveys={surveys}
                               disabled={disabled}
                               triggerExtras={trigger.condition_extras}
                               surveyQuestions={surveyQuestions}
                               fetchQuestionForSurveyId={(aSurveyId) => this.fetchQuestionForSurveyId(aSurveyId, true)}
                               fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                               fetchSurveyVersionCollections={fetchSurveyVersionCollections}
        />

        {trigger.condition_extras.question_conditions.map((condition, index) => (
          <ScheduleConditionCell index={index}
                                 surveys={surveys}
                                 disabled={disabled}
                                 condition={condition}
                                 updateCondition={(aCondition) => {
                                   trigger.condition_extras.question_conditions[index] = aCondition;
                                   this.setState({trigger:{...trigger}});
                                 }}
                                 menuButtonClicked={(aEvent) => {
                                   aEvent.preventDefault();
                                   aEvent.stopPropagation();
                                   this.setState({
                                     menuAnchorElement:aEvent.currentTarget,
                                     selectedConditionIndex:index
                                   });
                                 }}
                                 questionsForSurveyId={this.questionsForSurveyId}
                                 fetchQuestionForSurveyId={(aSurveyId) => this.fetchQuestionForSurveyId(aSurveyId, true)}
                                 fetchSurveyVersionCollections={fetchSurveyVersionCollections}
          />
        ))}

        <Add className="schedule-trigger-add-button"
             click={() => {
               if(!disabled){
                 trigger.condition_extras.question_conditions.push({
                   value:'',
                   operation:'contains',
                   question:[{value:''}],
                   question_type:'date',
                   parse_to_age:false
                 });
                 this.setState({trigger:{...trigger}});
               }
             }}
             titleKey={'ADD_CONDITION'}
        />

        <Separator label={i18next.t('THEN')}
                   margin={'30px'}
        />

        <div className="schedule-trigger-cell">
          <LabeledDropDown className="schedule-trigger-dropdown"
                           label={i18next.t('ALERT_TYPE')}
                           value={scheduleTriggerFireEventTypesOptionsArray().filter(option => {
                             return option.value === trigger.fire_event;
                           })}
                           options={scheduleTriggerFireEventTypesOptionsArray()}
                           disabled={disabled || isSettingTrigger}
                           required={true}
                           infoBlurb={i18next.t('ALERT_TYPE_EXPLANATION')}
                           handleSave={(aOption) => {
                             // TODO: Implement a confirmation if it changes that will delete the old data.
                             trigger.fire_event = aOption.value;
                             this.setState({trigger:{...trigger}});
                           }}
                           placeholder={i18next.t('ALERT_TYPE')}
          />

          {['admin_alert', 'patient_notification', 'survey_ending_notification', 'survey_starting_notification', 'survey_ending_admin_alert', 'survey_starting_admin_alert'].includes(trigger.fire_event) ?
            <LanguagePicker isSmall={true}
                            translations={translationsArray}
                            allTranslations={languages}
                            selectTranslation={(aTranslation) => this.setState({selectedTranslation:aTranslation})}
                            selectedTranslation={selectedTranslation}
                            addOrRemoveTranslation={(aTranslation) => this.addOrRemoveTranslation(aTranslation)}
            />
            :
            null
          }

          {['disable_survey', 'set_survey_end_date', 'set_survey_start_date'].includes(trigger.fire_event) ?
            <LabeledDropDown className="schedule-trigger-dropdown"
                             label={i18next.t('CHOOSE_TRIGGERED_SURVEY')}
                             value={fireSurveyOptionsArray.filter(option => {
                               return option.value === trigger.fire_survey_version_collection_id;
                             })}
                             options={fireSurveyOptionsArray}
                             disabled={disabled || isSettingTrigger}
                             required={true}
                             infoBlurb={i18next.t('CHOOSE_TRIGGERED_SURVEY_EXPLANATION')}
                             handleSave={(aOption) => {
                               trigger.fire_survey_version_collection_id = aOption.value;
                               this.setState({trigger:{...trigger}}, () => this.fetchQuestionForSurveyId(aOption.value));
                             }}
                             placeholder={i18next.t('CHOOSE_SURVEY')}
                             errorMessage={trigger.fire_survey_version_collection_id.length === 0 ? i18next.t('REQUIRED') : null}
            />
            :
            null
          }
        </div>

        {trigger && trigger.fire_event && trigger.fire_event.length > 0 ?
          <ScheduleTriggerExtras save={(aTriggerExtras) => {
            trigger.fire_extras = aTriggerExtras;
            this.setState({trigger:{...trigger}});
          }}
                                 surveys={surveys}
                                 disabled={disabled}
                                 triggerExtras={trigger.fire_extras}
                                 surveyQuestions={surveyQuestions}
                                 fetchQuestionForSurveyId={(aSurveyId) => this.fetchQuestionForSurveyId(aSurveyId, true)}
                                 fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                                 fetchSurveyVersionCollections={fetchSurveyVersionCollections}
          />
          :
          null
        }

        {trigger.fire_event === ScheduleTriggerFireEventTypeValues.admin_alert ?
          <div className="schedule-trigger-input-cell">
            <Separator margin={'10px'}/>

            <div className="schedule-trigger-cell">
              <LabeledTextInput className="schedule-trigger-text-input"
                                label={i18next.t('ADMIN_ALERT_MESSAGE') + ' (' + selectedTranslation.locale + ')'}
                                value={trigger.fire_extras[adminNotificationMessageKey]}
                                disabled={disabled}
                                infoBlurb={i18next.t('ADMIN_ALERT_MESSAGE_EXPLANATION')}
                                handleSave={(aEvent) => {
                                  trigger.fire_extras[adminNotificationMessageKey] = aEvent.target.value;
                                  this.setState({trigger:{...trigger}});
                                }}
                                placeholder={i18next.t('ADMIN_ALERT_MESSAGE')}
              />

              <LabeledTextInput className="schedule-trigger-text-input"
                                label={i18next.t('ADMIN_ALERT_EMAILS')}
                                value={trigger.fire_extras.admin_alert_emails.join(',')}
                                errorMessage={this.adminEmailsAreValid() ? '' : i18next.t('ADMIN_ALERT_EMAILS_INVALID')}
                                disabled={disabled}
                                infoBlurb={i18next.t('ADMIN_ALERT_EMAILS_EXPLANATION')}
                                handleSave={(aEvent) => {
                                  // TODO: Make sure the comma separated list is all emails.
                                  trigger.fire_extras.admin_alert_emails = aEvent.target.value.split(',');
                                  console.log('Mike trigger.fire_extras.admin_alert_emails:', trigger.fire_extras.admin_alert_emails);
                                  this.setState({trigger:{...trigger}});
                                }}
                                placeholder={i18next.t('ADMIN_ALERT_MESSAGE')}
              />
            </div>
          </div>
          :
          trigger.fire_event === ScheduleTriggerFireEventTypeValues.patient_notification ||
          trigger.fire_event === ScheduleTriggerFireEventTypeValues.survey_ending_notification ||
          trigger.fire_event === ScheduleTriggerFireEventTypeValues.survey_starting_notification ?
            <div className="schedule-trigger-input-cell">
              <Separator margin={'10px'}/>

              <LabeledTextInput className="schedule-trigger-text-input-full"
                                label={i18next.t('PATIENT_NOTIFICATION_MESSAGE') + ' (' + selectedTranslation.locale + ')'}
                                value={trigger.fire_extras[patientNotificationMessageKey]}
                                disabled={disabled}
                                infoBlurb={i18next.t('PATIENT_NOTIFICATION_MESSAGE_EXPLANATION')}
                                handleSave={(aEvent) => {
                                  trigger.fire_extras[patientNotificationMessageKey] = aEvent.target.value;
                                  this.setState({trigger:{...trigger}});
                                }}
                                placeholder={i18next.t('PATIENT_NOTIFICATION_MESSAGE')}
              />
            </div>
            :
            null
        }

        {trigger && trigger.id && trigger.id.length > 0 ?
          <div>
            <Separator margin={'10px'}/>

            <div className="schedule-trigger-remove-button-container">
              <RedButton name={i18next.t('REMOVE')}
                         onClick={() => this.setState({confirmRemoveModalOpen:true})}
              />
            </div>
          </div>
          :
          null
        }

        <ConfirmationModal title={i18next.t('REMOVE_SCHEDULE_TRIGGER')}
                           isOpen={confirmRemoveModalOpen}
                           reject={() => this.setState({confirmRemoveModalOpen:false})}
                           confirm={() => this.setState({saving:true, confirmRemoveModalOpen:false},
                             () => removeScheduleTrigger(trigger.id).then((newResult) => {
                               notifyScheduleTriggerRemovedSuccess();
                               this.setState({confirmRemoveModalOpen:false}, () => finishedSaving(true));
                             }, (newError) => {
                               notifyScheduleTriggerRemovedError(errorMessageFromServerError(newError));
                             })
                           )}
                           description={i18next.t('REMOVE_SCHEDULE_TRIGGER_DESCRIPTION')}
                           presentedInsideParentModal={true}
        />

        <Menu id="schedule-trigger-condition-cell-menu"
              open={Boolean(menuAnchorElement)}
              style={{zIndex:9999}}
              onClose={() => this.setState({menuAnchorElement:null, selectedConditionIndex:-1})}
              anchorEl={menuAnchorElement}
              keepMounted>
          <MenuItem style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();

                      trigger.condition_extras.question_conditions.splice(selectedConditionIndex, 1);
                      this.setState({trigger:{...trigger}, menuAnchorElement:null, selectedConditionIndex:-1});
                    }}>
            {i18next.t('REMOVE')}
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
