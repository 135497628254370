import React, {useEffect, useState} from 'react';
import './PublishScheduleModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";

const PublishScheduleModal = ({open, save, close, presentedInsideParentModal}) => {
  
  // Instance Variables
  
  const [triggerAddedToGroupEvents, setTriggerAddedToGroupEvents] = useState(false);
  
  // Effects
  
  useEffect(() => {
    if(!open){
      setTriggerAddedToGroupEvents(false);
    }
  }, [open]);
  
  // Render
  
  return (
    // <GeneralModal open={open}
    //               save={() => selectScheduleVersion(survey)}
    //               close={close}
    //               width={'400px'}
    //               title={i18next.t('SELECT_SCHEDULE_VERSION')}
    //               height={'230px'}
    //               containsDropDown={true}
    //               presentedInsideParentModal={presentedInsideParentModal}>
    <GeneralModal title={i18next.t('PUBLISH_SCHEDULE')}
                  width={'400px'}
                  height={'230px'}
                  isOpen={open}
                  maxWidth={400}
                  maxHeight={230}
                  handleSave={() => save(triggerAddedToGroupEvents)}
                  description={i18next.t('PUBLISH_SCHEDULE_DESCRIPTION')}
                  noTitleHeading={true}
                  showSaveButton={true}
                  containsDropDown={true}
                  handleCloseModal={() => close()}
                  maxChildrenHeight={180}
                  presentedInsideParentModal={presentedInsideParentModal}>
      <LabeledSwitch className="publish-schedule-modal-switch-input"
                     label={i18next.t('TRIGGER_ADDED_TO_GROUP_EVENTS')}
                     checked={triggerAddedToGroupEvents}
                     onChange={(aChecked) => setTriggerAddedToGroupEvents(aChecked)}
                     infoBlurb={i18next.t('TRIGGER_ADDED_TO_GROUP_EVENTS_DESCRIPTION')}
                     defaultChecked={false}
      />
    </GeneralModal>
  );
};

export default PublishScheduleModal
