import {LOAD_COMPANY, UPDATE_COMPANY, LOAD_COMPANY_ERROR, UPDATE_COMPANY_LOCALE, UPDATE_COMPANY_HEADLINE,
  LOAD_COMPANY_DATA_FOR_KEY, UPDATE_COMPANY_PAYMENT_TYPE, LOAD_COMPANY_DATA_ARRAY_FOR_KEY, LOAD_MINIFIED_COMPANY_DATA_FOR_KEY,
  UPDATE_COMPANY_LAST_ENROLMENT_DATE, LOAD_MINIFIED_COMPANY_DATA_ARRAY_FOR_KEY, UPDATE_COMPANY_HSC_TIMEOUT_MINUTES} from 'constants/ActionTypes';
import {gql} from '@apollo/client';
import EventTypes from 'constants/EventTypes'
import EventTracker from 'eventTracker';
import {allAdminRolesArray} from '../Helpers/AdminHelpers';
import {createApolloClient} from 'Helpers/ApolloClientHelpers';

export function loadCompany(){
  let query = `
  query loadCompany {
    user {
      id
      companies (roles: ["${allAdminRolesArray.join('", "')}"]) {
        id
        name
        role
        status
        locale
        hs_features
        payment_type
        hsc_timeout_minutes
        hyperwallet_api_url
        last_enrolment_date
        twilio_phone_number
        tremendous_api_url
        tremendous_api_token
        tremendous_campaign_id
        hyperwallet_api_username
        hyperwallet_program_token
        has_hyperwallet_api_password
        tremendous_funding_source_id
        has_valid_tremendous_credentials
        has_valid_hyperwallet_credentials
        number_of_uncleared_company_notification_receipts
        theme {
          id
          name
          headline
          logo_url
        }
        company_hs_features {
          enabled
          hs_feature{
            id
            title
            keyword
            editable_in_hsc
          }
        }
      }
    }
  }
  `;
  
  let variables = {};
  
  return (dispatch) => {
    return global.api.post("/queries", {query, variables}).then((newResult) => {
      let companies = newResult.data.data.user.companies;
      let company = companies[0];
      EventTracker.updateHsFeatures(company.hs_features);
      dispatch({type:LOAD_COMPANY, result:company});
    }, (newError) => {
      dispatch({type:LOAD_COMPANY_ERROR, newError});
    });
  };
}

export function loadCompanyDataWithKeysQueryAndTypes(aKeysArray, aQuery, aTypesArray){
  let variables = {};
  
  return (dispatch) => {
    let promise = global.api.post("/queries", {query:aQuery, variables:variables});
    promise.then((newResult) => {
      let companies = newResult.data.data.user.companies;
      
      if(companies && companies.length > 0){
        let company = companies[0];
        
        for(let index = 0; index < aKeysArray.length; index += 1){
          let key = aKeysArray[index];
          let type = aTypesArray[index];
          
          if(company[key]){
            dispatch({type:type, key:key, data:company[key]});
          }
        }
      }
      else{
        for(let index = 0; index < aKeysArray.length; index += 1){
          let key = aKeysArray[index];
          let type = aTypesArray[index];
          dispatch({type:type, key:key, data:{}});
        }
      }
    }, (newError) => {
      dispatch({type:LOAD_COMPANY_ERROR, error:newError});
    });
    return promise;
  };
}

export function loadCompanyQuests(){
  let query = `
  query loadCompany {
    user {
      id
      companies (roles: ["admin", "provider", "physician", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_9", "admin_level_10", "admin_level_11", "admin_level_66"]) {
        id
        core_group {
          id
          quests {
            id
            icon
            title
            admin_only
            quest_type
            icon_bg_color
            assigned_to_group_on
            survey {
              id
              name
              is_repeatable
              has_consent_question
              has_sensitive_data_question
            }
            survey_version_collection {
              id
              name
            }
            quest_schedule {
              id
              end_date
              start_date
              created_at
              updated_at
              enable_frequency
              enable_frequency_unit
              enable_duration
              enable_duration_unit
              start_date_table
              start_date_column
            }
            translations {
              title
              locale
              label_do
              description
              label_history
              label_description
            }
            objectives {
              id
              name
              type
              order
              description
              multiselect
              minimum_value
              minimum_value_string
              maximum_value
              maximum_value_string
              translations {
                locale
                name
                description
                maximum_value_string
                minimum_value_string
              }
              answers {
                id
                order
                has_image
                answer_text
                translations {
                  locale
                  answer_text
                  answer_explanation
                }
              }
            }
          }
        }
        groups (group_type: "company") {
          id
          quests {
            id
            icon
            title
            admin_only
            quest_type
            icon_bg_color
            assigned_to_group_on
            survey {
              id
              name
              is_repeatable
              has_consent_question
              has_sensitive_data_question
            }
            survey_version_collection {
              id
              name
            }
            quest_schedule {
              id
              end_date
              start_date
              created_at
              updated_at
              enable_frequency
              enable_frequency_unit
              enable_duration
              enable_duration_unit
              start_date_table
              start_date_column
            }
            translations {
              title
              locale
              label_do
              description
              label_history
              label_description
            }
            objectives {
              id
              name
              type
              order
              description
              multiselect
              maximum_value
              minimum_value
              maximum_value_string
              minimum_value_string
              translations {
                locale
                name
                description
                maximum_value_string
                minimum_value_string
              }
              answers {
                id
                answer_text
                order
                has_image
                translations {
                  locale
                  answer_text
                  answer_explanation
                }
              }
            }
          }
        }
      }
    }
  }
  `;
  return loadCompanyDataWithKeysQueryAndTypes(['core_group', 'groups'], query, [LOAD_COMPANY_DATA_FOR_KEY, LOAD_COMPANY_DATA_ARRAY_FOR_KEY]);
}

export function loadCompanyMinifiedGroups(){
  let query = `
  query loadCompany {
    user {
      id
      companies (roles: ["admin", "provider", "physician", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_9", "admin_level_10", "admin_level_11", "admin_level_66"]) {
        id
        core_group {
          id
          core
          name
          locale
          hs_features
          number_of_uncleared_company_notification_receipts
          quests {
            id
            title
            admin_only
            quest_type
            assigned_to_group_on
            survey {
              id
              name
            }
            survey_version_collection {
              id
              name
            }
            quest_schedule {
              id
              end_date
              start_date
              created_at
              updated_at
              enable_frequency
              enable_frequency_unit
              enable_duration
              enable_duration_unit
              start_date_table
              start_date_column
            }
          }
        }
        groups (group_type: "company") {
          id
          locale
          name
          core
          hs_features
          number_of_uncleared_company_notification_receipts
          quests {
            id
            title
            quest_type
            admin_only
            assigned_to_group_on
            survey {
              id
              name
            }
            survey_version_collection {
              id
              name
            }
            quest_schedule {
              id
              end_date
              start_date
              created_at
              updated_at
              enable_frequency
              enable_frequency_unit
              enable_duration
              enable_duration_unit
              start_date_table
              start_date_column
            }
          }
        }
      }
    }
  }
  `;
  return loadCompanyDataWithKeysQueryAndTypes(['core_group', 'groups'], query, [LOAD_MINIFIED_COMPANY_DATA_FOR_KEY, LOAD_MINIFIED_COMPANY_DATA_ARRAY_FOR_KEY]);
}

export function loadCompanySymptoms(){
  let query = `
  query loadCompany {
    user {
      id
      companies (roles: ["admin", "provider", "physician", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_9", "admin_level_10", "admin_level_11", "admin_level_66"]) {
        id
        core_group {
          id
          symptoms {
            id
            name
          }
          group_symptoms {
            id
            symptom_id
            extra_notes
          }
        }
        groups (group_type: "company") {
          id
          symptoms {
            id
            name
          }
          group_symptoms {
            id
            symptom_id
            extra_notes
          }
        }
      }
    }
  }
  `;
  return loadCompanyDataWithKeysQueryAndTypes(['core_group', 'groups'], query, [LOAD_MINIFIED_COMPANY_DATA_FOR_KEY, LOAD_MINIFIED_COMPANY_DATA_ARRAY_FOR_KEY]);
}

export function updateCompany(aCompanyId, aStudyName, aLocale, aLastEnrolmentDate, aPaymentType, aHSCTimeoutInMinutes){
  EventTracker.track(EventTypes.UPDATE_COMPANY);
  let query = `
  mutation UpdateUser($updateCompanyInput: UpdateCompanyInput!) {
    updateCompany(input: $updateCompanyInput) {
      company {
        id
        locale
        payment_type
        hsc_timeout_minutes
        last_enrolment_date
        theme {
          id
          name
          headline
          logo_url
        }
      }
    }
  }
  `;
  let params = {
    company_id:aCompanyId
  }
  if(aLocale){
    params['locale'] = aLocale;
  }
  if(aStudyName){
    params['headline'] = aStudyName;
  }
  if(aPaymentType){
    params['payment_type'] = aPaymentType;
  }
  if(aHSCTimeoutInMinutes){
    params['hsc_timeout_minutes'] = aHSCTimeoutInMinutes;
  }
  if(aLastEnrolmentDate){
    params['last_enrolment_date'] = aLastEnrolmentDate;
  }
  return (dispatch) => {
    return global.api.post("/queries",
      {query:query, variables:{updateCompanyInput:params}, operationName:'UpdateCompany'}
    ).then((newResult) => {
      let company = newResult.data.data.updateCompany.company;
      let locale = company.locale;
      let headline = company.theme.headline;
      let paymentType = company.payment_type;
      let lastEnrolmentDate = company.last_enrolment_date;
      let hscTimeoutInMinutes = company.hsc_timeout_minutes;
      
      dispatch({
        type:UPDATE_COMPANY_LOCALE,
        result:locale
      });
      dispatch({
        type:UPDATE_COMPANY_HEADLINE,
        result:headline
      });
      dispatch({
        type:UPDATE_COMPANY_LAST_ENROLMENT_DATE,
        result:lastEnrolmentDate
      });
      dispatch({
        type:UPDATE_COMPANY_PAYMENT_TYPE,
        result:paymentType
      });
      dispatch({
        type:UPDATE_COMPANY_HSC_TIMEOUT_MINUTES,
        result:hscTimeoutInMinutes
      });
      return newResult;
    }, (newError) => {
      dispatch({type:UPDATE_COMPANY, error:newError});
      return Promise.reject(newError);
    });
  };
}

export function updateCompanyPaymentType(aCompanyId, aPaymentType){
  EventTracker.track(EventTypes.UPDATE_COMPANY);
  let query = `
  mutation UpdateUser($updateCompanyInput: UpdateCompanyInput!) {
    updateCompany(input: $updateCompanyInput) {
      company {
        id
        payment_type
      }
    }
  }
  `;
  let variables = {
    updateCompanyInput:{
      company_id:aCompanyId,
      payment_type:aPaymentType
    }
  };
  let operationName = `UpdateCompany`;
  
  return (dispatch) => {
    return global.api.post("/queries", {query:query, variables:variables, operationName:operationName}).then((newResult) => {
      let payment_type = newResult.data.data.updateCompany.company.payment_type;
      
      dispatch({
        type:UPDATE_COMPANY_PAYMENT_TYPE,
        result:payment_type
      });
      return newResult;
    }, (newError) => {
      dispatch({type:UPDATE_COMPANY, error:newError});
      return newError;
    });
  };
}
export function companyInviteSearch(aSearchString, companyId){
  let query = `
      query getCompanyInvites($query: String, $company_id: ID!, $without_accepted: Boolean) {
        company_invites(query: $query, company_id: $company_id, without_accepted: $without_accepted) {
          id
          email
          accepted
        }
      }
    `;
  let variables = {query:aSearchString, company_id:companyId, without_accepted:true};
  
  return (dispatch) => {
    return global.api.post("/queries", {query:query, variables:variables});
  };
}

export function closeStudy(aCloseoutSignature, aAdminEmail, aAdminPassword){
  EventTracker.track(EventTypes.CLOSE_STUDY);
  
  let query = gql`
  mutation CloseStudy($closeStudyInput: CloseStudyInput!) {
    closeStudy(input: $closeStudyInput) {
      company {
        id
        status
      }
    }
  }
  `;
  
  return (dispatch, getState) => {
    const state = getState();
    const apolloClient = createApolloClient();
    
    const variables = {
      closeStudyInput:{
        company_id:state.company?.company?.id,
        admin_email:aAdminEmail,
        admin_password:aAdminPassword,
        closeout_signature:aCloseoutSignature
      }
    };
    
    return apolloClient.mutate({mutation:query, variables:variables, context:{hasUpload:true}});
  };
}
