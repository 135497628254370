import actions from 'actions';
import {connect} from "react-redux";
import SurveyComponent from "../Components/SurveyComponent/SurveyComponent";
import {createApolloClient} from "Helpers/ApolloClientHelpers";

const mapStateToProps = (state) => {
  return {
    user:state.current && state.current.user,
    client:createApolloClient()
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConditions:(aSearchString, aLimit, aOffset) => {
      return dispatch(actions.getConditions(aSearchString, aLimit, aOffset));
    },
    fetchMedicationsWithCountryCode:(aQuery, aMedCountryCode, aLimit, aOffset) => {
      return dispatch(actions.fetchMedicationsWithCountryCode(aQuery, aMedCountryCode, aLimit, aOffset));
    },
    getMedraConditions:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getMedraConditions(aLimit, aOffset, aSearchString));
    },
    updateSurveyResponseLockState:(aSurveyResponseId, aLockedState) => {
      return dispatch(actions.updateSurveyResponseLockState(aSurveyResponseId, aLockedState));
    },
    respondToSurvey:(aSurveyId, aSurveyResponseId, aAnswers, aUserId, aAdminId, aClient, aIsTestResponse, aStayOnCurrentPage) => {
      return dispatch(actions.respondToSurvey(aSurveyId, aSurveyResponseId, aAnswers, aUserId, aAdminId, aClient, aIsTestResponse, aStayOnCurrentPage));
    },
    questionBeforeKey:(aSurveyResponseDetailId, aSurveyId, aQuestionKey, aUserId, aGetQuestionAfter, aSurveyResponseId) => {
      return dispatch(actions.questionBeforeKey(aSurveyResponseDetailId, aSurveyId, aQuestionKey, aUserId, aGetQuestionAfter, aSurveyResponseId));
    },
    firstSurvey:(aSurveyId, aUserId, aSurveyResponseId) => {
      return dispatch(actions.firstSurvey(aSurveyId, aUserId, aSurveyResponseId));
    },
    getDynamicListItems:(aDynamicListId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getDynamicListItems(aDynamicListId, aLimit, aOffset, aSearchString));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SurveyComponent);
