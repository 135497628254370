import EventTypes from 'constants/EventTypes'
import EventTracker from 'eventTracker'
import {deleteEmptyProperties} from "../Helpers/Helpers";

export function saveSchedule(aScheduleId = null, aName, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove){
  if(aScheduleId){
    EventTracker.track(EventTypes.UPDATE_SCHEDULE);
  }
  else{
    EventTracker.track(EventTypes.CREATE_SCHEDULE);
  }
  let query = `
    mutation SaveScheduleMutation ($saveScheduleMutationInput: SaveScheduleMutationInput!) {
      saveSchedule (input: $saveScheduleMutationInput){
        schedule {
          id
          name
          created_at
          updated_at
          publish_state
          updated_by_user {
            id
            last_name
            first_name
          }
          schedule_versions {
            id
            created_at
            updated_at
            version_number
          }
          current_schedule_version {
            id
            created_at
            updated_at
            version_number
          }
          draft_schedule_version {
            id
            created_at
            updated_at
            version_number
            groups {
              id
              name
            }
            additional_triggers {
              id
              created_at
              updated_at
              condition_event
              condition_extras
              condition_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              condition_survey_version_collection {
                id
                name
                reference
              }
              fire_event
              fire_extras
              fire_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              fire_survey_version_collection {
                id
                name
                reference
              }
              is_time_based
              time_based_event
              time_based_extras
              time_based_additional_app_column {
                id
              }
              time_based_survey_version_collection {
                id
                name
                reference
              }
            }
            survey_version_collections {
              id
              name
              reference
              created_at
              updated_at
              is_archived
              is_editable
              current_survey {
                id
                name
                reference
                admin_only
                is_repeatable
                version_number
                onboarding_survey
                terms_and_privacy
                published_by_user {
                  id
                  last_name
                  first_name
                }
                updated_by_user {
                  id
                  last_name
                  first_name
                }
              }
              survey_responses {
                id
                admin_id
                complete
                progress
                admin_name
                created_at
                lock_state
                updated_at
                completed_at
                completed_by_admin
              }
              nested_survey_responses {
                id
                admin_id
                complete
                progress
                admin_name
                created_at
                lock_state
                updated_at
                completed_at
                completed_by_admin
              }
              quest_user_join {
                start_date
                end_date
                visibility
              }
              dependency_survey_version_collections {
                id
                name
                reference
                nested_survey_responses {
                  id
                  admin_id
                  complete
                  progress
                  admin_name
                  created_at
                  lock_state
                  updated_at
                  completed_at
                  completed_by_admin
                }
              }
              dependent_survey_version_collections {
                id
                name
                reference
                nested_survey_responses {
                  id
                  admin_id
                  complete
                  progress
                  admin_name
                  created_at
                  lock_state
                  updated_at
                  completed_at
                  completed_by_admin
                }
              }
              start_triggers {
                id
                created_at
                updated_at
                number_of_fired_events
                condition_event
                condition_extras
                condition_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                condition_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_event
                fire_extras
                fire_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_survey_version_collection {
                  id
                  name
                  reference
                }
                is_time_based
                time_based_event
                time_based_extras
                time_based_additional_app_column {
                  id
                }
                time_based_survey_version_collection {
                  id
                  name
                  reference
                }
              }
              end_triggers {
                id
                created_at
                updated_at
                number_of_fired_events
                condition_event
                condition_extras
                condition_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                condition_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_event
                fire_extras
                fire_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_survey_version_collection {
                  id
                  name
                  reference
                }
                is_time_based
                time_based_event
                time_based_extras
                time_based_additional_app_column {
                  id
                }
                time_based_survey_version_collection {
                  id
                  name
                  reference
                }
              }
              additional_triggers {
                id
                created_at
                updated_at
                number_of_fired_events
                condition_event
                condition_extras
                condition_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                condition_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_event
                fire_extras
                fire_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_survey_version_collection {
                  id
                  name
                  reference
                }
                is_time_based
                time_based_event
                time_based_extras
                time_based_additional_app_column {
                  id
                }
                time_based_survey_version_collection {
                  id
                  name
                  reference
                }
              }
              survey_triggers {
                id
                created_at
                updated_at
                number_of_fired_events
                condition_event
                condition_extras
                condition_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                condition_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_event
                fire_extras
                fire_extras_reference_date_survey_version_collection {
                  id
                  name
                  reference
                }
                fire_survey_version_collection {
                  id
                  name
                  reference
                }
                is_time_based
                time_based_event
                time_based_extras
                time_based_additional_app_column {
                  id
                }
                time_based_survey_version_collection {
                  id
                  name
                  reference
                }
              }
              payment_triggers {
                id
                title
                target_id
                target_type
                target_name
                target_action
                action_criteria
                number_of_payments
                currency
                amount
                limit_per_user
                manual_verification_required
                is_enabled
                start_at
                end_at
              }
            }
          }
        }
      }
    }
  `;
  let params = {};
  let operationName = `SaveTemplateMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['name'] = aName;
    
    if(aScheduleId){
      params['schedule_id'] = aScheduleId;
    }
    
    let surveys = [];
    
    if(aSurveysToAdd && aSurveysToAdd.length > 0){
      for(let survey of aSurveysToAdd){
        if(survey.id && survey.id.length > 0){
          surveys.push({survey_version_collection_id:survey.id});
        }
      }
    }
    if(aSurveysToRemove && aSurveysToRemove.length > 0){
      for(let survey of aSurveysToRemove){
        if(survey.id && survey.id.length > 0){
          surveys.push({delete:true, survey_version_collection_id:survey.id});
        }
      }
    }
    if(surveys.length > 0){
      params['schedule_survey_version_collections'] = surveys;
    }
    
    let groups = [];
    
    if(aGroupsToAdd && aGroupsToAdd.length > 0){
      for(let group of aGroupsToAdd){
        if(group.id && group.id.length > 0){
          groups.push({group_id:group.id});
        }
      }
    }
    if(aGroupsToRemove && aGroupsToRemove.length > 0){
      for(let group of aGroupsToRemove){
        if(group.id && group.id.length > 0){
          groups.push({delete:true, group_id:group.id});
        }
      }
    }
    if(groups.length > 0){
      params['group_schedules'] = groups;
    }
    
    let triggers = [];

    if(aTriggersToAdd && aTriggersToAdd.length > 0){
      for(let trigger of aTriggersToAdd){
        triggers.push({
          id: trigger.id,
          condition_event: trigger.condition_event,
          condition_extras: trigger.condition_extras,
          condition_survey_version_collection_id: trigger.condition_survey_version_collection?.id,
          fire_event: trigger.fire_event,
          fire_extras: trigger.fire_extras,
          fire_survey_version_collection_id: trigger.fire_survey_version_collection?.id,
          is_time_based: trigger.is_time_based,
          time_based_event: trigger.time_based_event,
          time_based_extras: trigger.time_based_extras,
          time_based_survey_version_collection_id: trigger.time_based_survey_version_collection?.id,
          time_based_additional_app_column_id: trigger.time_based_additional_app_column?.id
        });
      }
    }
    if(aTriggersToRemove && aTriggersToRemove.length > 0){
      for(let trigger of aTriggersToRemove){
        if(trigger.id && trigger.id.length > 0){
          triggers.push({delete:true, trigger_id:trigger.id});
        }
      }
    }
    if(triggers.length > 0){
      params['triggers'] = triggers;
    }
    
    let variables = {
      saveScheduleMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function fetchSchedule(aScheduleId, aUserId = null){
  EventTracker.track(EventTypes.FETCH_SCHEDULE);
  let query = `
    query getSchedule ($company_id: ID!, $schedule_id: ID!, $user_id: ID) {
      schedule (company_id: $company_id, schedule_id: $schedule_id, user_id: $user_id){
        id
        name
        created_at
        updated_at
        publish_state
        updated_by_user {
          id
          last_name
          first_name
        }
        schedule_versions {
          id
          created_at
          updated_at
          version_number
        }
        current_schedule_version {
          id
          created_at
          updated_at
          version_number
        }
        draft_schedule_version {
          id
          created_at
          updated_at
          version_number
          groups {
            id
            name
          }
          additional_triggers {
            id
            created_at
            updated_at
            condition_event
            condition_extras
            condition_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            condition_survey_version_collection {
              id
              name
              reference
            }
            fire_event
            fire_extras
            fire_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            fire_survey_version_collection {
              id
              name
              reference
            }
            is_time_based
            time_based_event
            time_based_extras
            time_based_additional_app_column {
              id
            }
            time_based_survey_version_collection {
              id
              name
              reference
            }
          }
          survey_version_collections {
            id
            name
            reference
            created_at
            updated_at
            is_archived
            is_editable
            current_survey {
              id
              name
              reference
              admin_only
              is_repeatable
              version_number
              onboarding_survey
              terms_and_privacy
              published_by_user {
                id
                last_name
                first_name
              }
              updated_by_user {
                id
                last_name
                first_name
              }
            }
            survey_responses {
              id
              admin_id
              complete
              progress
              admin_name
              created_at
              lock_state
              updated_at
              completed_at
              completed_by_admin
            }
            nested_survey_responses {
              id
              admin_id
              complete
              progress
              admin_name
              created_at
              lock_state
              updated_at
              completed_at
              completed_by_admin
            }
            quest_user_join {
              start_date
              end_date
              visibility
            }
            dependency_survey_version_collections {
              id
              name
              reference
              nested_survey_responses {
                id
                admin_id
                complete
                progress
                admin_name
                created_at
                lock_state
                updated_at
                completed_at
                completed_by_admin
              }
            }
            dependent_survey_version_collections {
              id
              name
              reference
              nested_survey_responses {
                id
                admin_id
                complete
                progress
                admin_name
                created_at
                lock_state
                updated_at
                completed_at
                completed_by_admin
              }
            }
            start_triggers {
              id
              created_at
              updated_at
              number_of_fired_events
              condition_event
              condition_extras
              condition_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              condition_survey_version_collection {
                id
                name
                reference
              }
              fire_event
              fire_extras
              fire_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              fire_survey_version_collection {
                id
                name
                reference
              }
              is_time_based
              time_based_event
              time_based_extras
              time_based_additional_app_column {
                id
              }
              time_based_survey_version_collection {
                id
                name
                reference
              }
            }
            end_triggers {
              id
              created_at
              updated_at
              number_of_fired_events
              condition_event
              condition_extras
              condition_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              condition_survey_version_collection {
                id
                name
                reference
              }
              fire_event
              fire_extras
              fire_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              fire_survey_version_collection {
                id
                name
                reference
              }
              is_time_based
              time_based_event
              time_based_extras
              time_based_additional_app_column {
                id
              }
              time_based_survey_version_collection {
                id
                name
                reference
              }
            }
            additional_triggers {
              id
              created_at
              updated_at
              number_of_fired_events
              condition_event
              condition_extras
              condition_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              condition_survey_version_collection {
                id
                name
                reference
              }
              fire_event
              fire_extras
              fire_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              fire_survey_version_collection {
                id
                name
                reference
              }
              is_time_based
              time_based_event
              time_based_extras
              time_based_additional_app_column {
                id
              }
              time_based_survey_version_collection {
                id
                name
                reference
              }
            }
            survey_triggers {
              id
              created_at
              updated_at
              number_of_fired_events
              condition_event
              condition_extras
              condition_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              condition_survey_version_collection {
                id
                name
                reference
              }
              fire_event
              fire_extras
              fire_extras_reference_date_survey_version_collection {
                id
                name
                reference
              }
              fire_survey_version_collection {
                id
                name
                reference
              }
              is_time_based
              time_based_event
              time_based_extras
              time_based_additional_app_column {
                id
              }
              time_based_survey_version_collection {
                id
                name
                reference
              }
            }
            payment_triggers {
              id
              title
              target_id
              target_type
              target_name
              target_action
              action_criteria
              number_of_payments
              currency
              amount
              limit_per_user
              manual_verification_required
              is_enabled
              start_at
              end_at
            }
          }
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['schedule_id'] = aScheduleId;
    
    if(aUserId){
      variables['user_id'] = aUserId;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchSchedules(aLimit, aOffset, aSearchString, aUserId, aGroupId, aOnlyRemoved){
  EventTracker.track(EventTypes.FETCH_SCHEDULES);
  let query = `
    query getSchedules ($company_id: ID!, $limit: Int, $offset: Int, $search_string: String, $user_id: ID, $group_id: ID, $only_removed: Boolean) {
      schedules (company_id: $company_id, limit: $limit, offset: $offset, search_string: $search_string, user_id: $user_id, group_id: $group_id, only_removed: $only_removed){
        schedules {
          id
          name
          created_at
          updated_at
          publish_state
          updated_by_user {
            id
            last_name
            first_name
          }
          draft_schedule_version{
            version_number
            groups {
              id
              name
            }
          }
          current_schedule_version{
            version_number
            groups {
              id
              name
            }
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aUserId){
      variables['user_id'] = aUserId;
    }
    if(aGroupId){
      variables['group_id'] = aGroupId;
    }
    if(aOnlyRemoved){
      variables['only_removed'] = aOnlyRemoved;
    }
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function removeSchedule(aScheduleId){
  EventTracker.track(EventTypes.REMOVE_SCHEDULE);
  let query = `
    mutation RemoveScheduleMutation($removeScheduleMutationInput: RemoveScheduleMutationInput!) {
        removeScheduleMutation(input: $removeScheduleMutationInput) {
          schedule {
            id
          }
        }
      }
  `;
  let params = {};
  let operationName = `RemoveScheduleMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['schedule_id'] = aScheduleId;
    
    let variables = {
      removeScheduleMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function publishSchedule(aScheduleId, aTriggerAddedToGroupEvents){
  EventTracker.track(EventTypes.PUBLISH_SCHEDULE);
  let query = `
    mutation PublishDraftScheduleVersionMutation ($publishDraftScheduleVersionMutationInput: PublishDraftScheduleVersionMutationInput!) {
      publishDraftScheduleVersionMutation (input: $publishDraftScheduleVersionMutationInput){
        schedule {
          id
          name
        }
      }
    }
  `;
  let params = {};
  let operationName = `PublishDraftScheduleVersionMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['schedule_id'] = aScheduleId;
    params['trigger_added_to_group_events'] = aTriggerAddedToGroupEvents;
    
    let variables = {
      publishDraftScheduleVersionMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function saveScheduleTrigger(aScheduleId, aScheduleTrigger){
  if(aScheduleTrigger && aScheduleTrigger.id){
    EventTracker.track(EventTypes.UPDATE_SCHEDULE_Trigger);
  }
  else{
    EventTracker.track(EventTypes.CREATE_SCHEDULE_TRIGGER);
  }
  let query = `
    mutation SaveTriggerMutation ($saveTriggerMutationInput: SaveTriggerMutationInput!) {
      saveTrigger (input: $saveTriggerMutationInput){
        trigger {
          id
          created_at
          updated_at
          condition_event
          condition_extras
          condition_extras_reference_date_survey_version_collection {
            id
            name
            reference
          }
          condition_survey_version_collection {
            id
          }
          fire_event
          fire_extras
          fire_extras_reference_date_survey_version_collection {
            id
            name
            reference
          }
          fire_survey_version_collection {
            id
          }
          is_time_based
          time_based_event
          time_based_extras
          time_based_additional_app_column {
            id
          }
          time_based_survey_version_collection {
            id
          }
        }
      }
    }
  `;
  let params = {};
  let operationName = `SaveTriggerMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    // params['id'] = aScheduleTrigger.id;
    params['schedule_id'] = aScheduleId;
    const scheduleTrigger = {...aScheduleTrigger};
    deleteEmptyProperties(aScheduleTrigger);
    
    for(let key of Object.keys(scheduleTrigger)){
      params[key] = scheduleTrigger[key];
    }
    let variables = {
      saveTriggerMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function fetchScheduleVersion(aScheduleVersionId){
  EventTracker.track(EventTypes.FETCH_SCHEDULE_VERSION);
  let query = `
    query getScheduleVersion ($company_id: ID!, $schedule_version_id: ID!, $user_id: ID) {
      schedule_version (company_id: $company_id, schedule_version_id: $schedule_version_id, user_id: $user_id){
        id
        created_at
        updated_at
        version_number
        additional_triggers {
          id
          created_at
          updated_at
          condition_event
          condition_extras
          condition_extras_reference_date_survey_version_collection {
            id
            name
            reference
          }
          condition_survey_version_collection {
            id
            name
            reference
          }
          fire_event
          fire_extras
          fire_extras_reference_date_survey_version_collection {
            id
            name
            reference
          }
          fire_survey_version_collection {
            id
            name
            reference
          }
          is_time_based
          time_based_event
          time_based_extras
          time_based_additional_app_column {
            id
          }
          time_based_survey_version_collection {
            id
            name
            reference
          }
        }
        survey_version_collections {
          id
          name
          reference
          created_at
          updated_at
          is_archived
          is_editable
          current_survey {
            id
            name
            reference
            admin_only
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
          survey_responses {
            id
            admin_id
            complete
            progress
            admin_name
            created_at
            lock_state
            updated_at
            completed_at
            completed_by_admin
          }
          nested_survey_responses {
            id
            admin_id
            complete
            progress
            admin_name
            created_at
            lock_state
            updated_at
            completed_at
            completed_by_admin
          }
          quest_user_join {
            end_date
            start_date
            visibility
          }
          dependent_survey_version_collections {
            id
            name
            reference
            nested_survey_responses {
              id
              admin_id
              complete
              progress
              admin_name
              created_at
              lock_state
              updated_at
              completed_at
              completed_by_admin
            }
          }
          start_triggers {
            id
            created_at
            updated_at
            number_of_fired_events
            condition_event
            condition_extras
            condition_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            condition_survey_version_collection {
              id
              name
              reference
            }
            fire_event
            fire_extras
            fire_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            fire_survey_version_collection {
              id
              name
              reference
            }
            is_time_based
            time_based_event
            time_based_extras
            time_based_additional_app_column {
              id
            }
            time_based_survey_version_collection {
              id
              name
              reference
            }
          }
          end_triggers {
            id
            created_at
            updated_at
            number_of_fired_events
            condition_event
            condition_extras
            condition_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            condition_survey_version_collection {
              id
              name
              reference
            }
            fire_event
            fire_extras
            fire_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            fire_survey_version_collection {
              id
              name
              reference
            }
            is_time_based
            time_based_event
            time_based_extras
            time_based_additional_app_column {
              id
            }
            time_based_survey_version_collection {
              id
              name
              reference
            }
          }
          additional_triggers {
            id
            created_at
            updated_at
            number_of_fired_events
            condition_event
            condition_extras
            condition_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            condition_survey_version_collection {
              id
              name
              reference
            }
            fire_event
            fire_extras
            fire_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            fire_survey_version_collection {
              id
              name
              reference
            }
            is_time_based
            time_based_event
            time_based_extras
            time_based_additional_app_column {
              id
            }
            time_based_survey_version_collection {
              id
              name
              reference
            }
          }
          survey_triggers {
            id
            created_at
            updated_at
            number_of_fired_events
            condition_event
            condition_extras
            condition_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            condition_survey_version_collection {
              id
              name
              reference
            }
            fire_event
            fire_extras
            fire_extras_reference_date_survey_version_collection {
              id
              name
              reference
            }
            fire_survey_version_collection {
              id
              name
              reference
            }
            is_time_based
            time_based_event
            time_based_extras
            time_based_additional_app_column {
              id
            }
            time_based_survey_version_collection {
              id
              name
              reference
            }
          }
          payment_triggers {
            id
            title
            amount
            end_at
            currency
            start_at
            target_id
            is_enabled
            target_type
            target_name
            target_action
            limit_per_user
            action_criteria
            number_of_payments
            manual_verification_required
          }
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['schedule_version_id'] = aScheduleVersionId;
    
    return global.api.post("/queries", { query, variables })
  };
}

export function removeScheduleTrigger(aScheduleId, aScheduleTriggerId){
  EventTracker.track(EventTypes.REMOVE_SCHEDULE_TRIGGER);
  let query = `
    mutation RemoveSchedulerTriggerMutation ($removeSchedulerTriggerMutationInput: RemoveSchedulerTriggerMutationInput!) {
      removeSchedulerTriggerMutation (input: $removeSchedulerTriggerMutationInput){
        trigger {
          id
        }
      }
    }
  `;
  let params = {};
  let operationName = `RemoveSchedulerTriggerMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['id'] = aScheduleTriggerId;
    params['schedule_id'] = aScheduleId;
    
    let variables = {
      removeSchedulerTriggerMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function fetchPatientsInSchedule(aLimit, aOffset, aSearchString, aScheduleId){
  EventTracker.track(EventTypes.FETCH_PATIENTS_IN_SCHEDULE);
  let query = `
    query findUsers($company_id: ID!, $offset: Int, $limit: Int, $schedule_id: ID, $query: String!) {
      user_page(company_id: $company_id, roles: ["patient"], offset: $offset, limit: $limit, schedule_id: $schedule_id, query: $query) {
        users {
          id
          email
          gender
          image_id
          last_name
          first_name
          subject_status
          groups (group_type: "company", core: false, company_id: $company_id) {
            id
            core
            name
            group_type
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aScheduleId){
      variables['schedule_id'] = aScheduleId;
    }
    if(aSearchString){
      variables['query'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchScheduleVersionsForSchedule(aScheduleId, aLimit, aOffset){
  EventTracker.track(EventTypes.FETCH_SCHEDULE_VERSIONS);
  let query = `
    schedule_versions (company_id: $company_id, schedule_id: $schedule_id, limit: $limit, offset: $offset){
      schedule_versions {
        id
        created_at
        updated_at
        version_number
      }
      total
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['schedule_id'] = aScheduleId;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    return global.api.post("/queries", { query, variables })
  };
}
