import React from "react";
import {Translation} from "react-i18next";

export const SurveyTypesArray = [
  'blank',
  'text',
  'number',
  'number_choice',
  'radio',
  'checkbox',
  'rank',
  'range',
  'slider',
  'date',
  'time',
  'datetime',
  'year',
  'monthyear',
  'list',
  'medication',
  'multi_medication',
  'condition',
  'multi_condition',
  'econsent',
  'econsent_hsc',
  'image_upload',
  'pdf_upload',
  'csv_upload',
  'audio',
  'dynamic_list'
];

export const SurveyTypeDetailsArray = [
  {type:'blank', title:'BLANK', description:'BLANK_DESCRIPTION'},
  {type:'text', title:'TEXT', description:'TEXT_VAR_DESCRIPTION'},
  {type:'number', title:'NUMBER', description:'NUMBER_EXPLANATION'},
  {type:'number_choice', title:'NUMBER_CHOICE', description:'NUMBER_CHOICE_EXPLANATION'},
  {type:'radio', title:'RADIO', description:'RADIO_EXPLANATION'},
  {type:'checkbox', title:'CHECKBOX', description:'CHECKBOX_DESCRIPTION'},
  {type:'rank', title:'RANK', description:'RANK_EXPLANATION'},
  {type:'range', title:'RANGE', description:'RANGE_EXPLANATION'},
  {type:'slider', title:'SLIDER', description:'SLIDER_EXPLANATION'},
  {type:'date', title:'DATE', description:'DATE_DESCRIPTION'},
  {type:'time', title:'TIME_PROPER_CAPITALIZED', description:'TIME_EXPLANATION'},
  {type:'datetime', title:'DATETIME', description:'DATETIME_DESCRIPTION'},
  {type:'year', title:'YEAR', description:'YEAR_EXPLANATION'},
  {type:'monthyear', title:'MONTH_YEAR', description:'MONTH_YEAR_EXPLANATION'},
  {type:'list', title:'LIST', description:'LIST_EXPLANATION'},
  {type:'medication', title:'MEDICATION', description:'MEDICATION_EXPLANATION'},
  {type:'multi_medication', title:'MULTI_MEDICATION', description:'MULTI_MEDICATION_EXPLANATION'},
  {type:'condition', title:'CONDITION', description:'CONDITION_DESCRIPTION'},
  {type:'multi_condition', title:'MULTI_CONDITION', description:'MULTI_CONDITION_EXPLANATION'},
  {type:'econsent', title:'ECONSENT', description:'ECONSENT_EXPLANATION'},
  {type:'econsent_hsc', title:'ECONSENT_HSC', description:'ECONSENT_HSC_EXPLANATION'},
  {type:'image_upload', title:'IMAGE_UPLOAD', description:'IMAGE_UPLOAD_DESCRIPTION'},
  {type:'pdf_upload', title:'PDF_UPLOAD', description:'PDF_UPLOAD_EXPLANATION'},
  {type:'csv_upload', title:'CSV_UPLOAD', description:'CSV_UPLOAD_DESCRIPTION'},
  {type:'audio', title:'AUDIO', description:'AUDIO_DESCRIPTION'},
  {type:'dynamic_list', title:'DYNAMIC_LIST', description:'DYNAMIC_LIST_EXPLANATION'}
];

export const operationsArray = ['antipair', 'contains', 'not_contains', 'equal', 'greater_than', 'less_than', 'not_equal', 'or', 'unanswered'];
export const multipleValueOperationsArray = ['contains', 'not_contains', 'or'];

export const operationOptionsArray = [
  {label:<Translation>{(t, {i18n}) => t('ANTIPAIR')}</Translation>, value:'antipair'},
  {label:<Translation>{(t, {i18n}) => t('CONTAINS')}</Translation>, value:'contains'},
  {label:<Translation>{(t, {i18n}) => t('NOT_CONTAINS')}</Translation>, value:'not_contains'},
  {label:<Translation>{(t, {i18n}) => t('EQUAL')}</Translation>, value:'equal'},
  {label:<Translation>{(t, {i18n}) => t('GREATER_THAN')}</Translation>, value:'greater_than'},
  {label:<Translation>{(t, {i18n}) => t('LESS_THAN')}</Translation>, value:'less_than'},
  {label:<Translation>{(t, {i18n}) => t('NOT_EQUAL')}</Translation>, value:'not_equal'},
  {label:<Translation>{(t, {i18n}) => t('OR')}</Translation>, value:'or'},
  {label:<Translation>{(t, {i18n}) => t('ANSWERED')}</Translation>, value:'answered'},
  {label:<Translation>{(t, {i18n}) => t('UNANSWERED')}</Translation>, value:'unanswered'}
];

export const allowedWriteTablesAndCustom = ['Custom', 'User', 'AdditionalAppColumn', 'HealthCareProviderInformation'];
export const allowedWriteTableAndCustomOptionsArray = [
  {label:'Custom', value:'Custom'},
  {label:'User', value:'User'},
  {label:'AdditionalAppColumn', value:'AdditionalAppColumn'},
  {label:'HealthCareProviderInformation', value:'HealthCareProviderInformation'}
];

export const allowedWriteTables = ['User', 'AdditionalAppColumn', 'HealthCareProviderInformation'];
export const allowedWriteTableOptionsArray = [
  {label:'User', value:'User'},
  {label:'AdditionalAppColumn', value:'AdditionalAppColumn'},
  {label:'HealthCareProviderInformation', value:'HealthCareProviderInformation'}
];

export const allowedWriteColumns = {
  User:['email', 'first_name', 'last_name', 'geocoded_country', 'geocoded_state', 'birth_year', 'gender', 'address', 'primary_contact_number', 'secondary_contact_number', 'preferred_method_of_contact', 'alternative_contact', 'edc_id'],
  HealthCareProviderInformation:['first_name', 'last_name', 'role', 'phone_number', 'email', 'user_id', 'physician_specialty', 'facility_name']
};

export const surveyTriggerTypes = ['safety_alert', 'signature_required', 'mrf_alert', 'stool_alert', 'customised_alert', 'discrepancy_alert', 'patient_notification'];
export const triggerTypesRequiringAlertTypes = [];
export const triggerTypesRequiringCustomMessages = ['customised_alert', 'patient_notification'];

export const allowedWriteColumnOptionsArray = (aTable) => {
  let returnValue = [];
  
  if(allowedWriteTables.includes(aTable)){
    for(let column of allowedWriteColumns[aTable]){
      returnValue.push({value:column, label:column});
    }
  }
  return returnValue;
};
