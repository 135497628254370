import {LOGIN, FORGOT, LOGOUT, LOGIN_ERROR, FORGOT_ERROR, LOAD_PATIENT, SIGNUP_ERROR, RESET_PASSWORD, PERMITTED_ITEMS,
  LOAD_CURRENT_USER, LOAD_PATIENT_DATA, LOAD_PATIENT_ERROR, SET_CURRENT_PATIENT, RESET_PASSWORD_ERROR,
  LOAD_SYNCED_DEVICE_DATA, UPDATE_CURRENT_USER_ERROR} from 'constants/ActionTypes';
import i18n from "../i18n";
import axios from 'axios';
import {push} from 'react-router-redux';
import {endPoint} from "../Helpers/EndPointHelpers";
import EventTypes from 'constants/EventTypes';
import EventTracker from 'eventTracker';
import {setDefaultLocale} from "react-datepicker";
import moment from "moment/moment";

const redirectUser = (aUser, aDispatch) => {
  if(aUser && aUser.id){
    EventTracker.identify(aUser.id, aUser.hs_features);
    
    if(aUser.companies_users && aUser.companies_users.length > 0 && aUser.companies_users[0].role){
      let company = aUser.companies_users[0];
      EventTracker.setCompanyId(company.company_id);
      const surveyLinkId = new URLSearchParams(window.location.search).get('survey_link_id');
      
      if(surveyLinkId && surveyLinkId.length > 0){
        aDispatch(push('manage/surveyLinks?survey_link_id=' + surveyLinkId));
      }
      else{
        if(company.role === 'admin_level_8'){
          aDispatch(push('manage/patients'));
        }
        else if(company.role === 'admin_level_6'){
          aDispatch(push('manage/reports'));
        }
        else if(company.role === 'admin_level_5'){
          aDispatch(push('manage/admins'));
        }
        else if(company.role === 'admin_level_11'){
          aDispatch(push('manage/settings'));
        }
        else{
          aDispatch(push('groups/patients'));
        }
      }
    }
    else{
      aDispatch(push('groups/patients'));
    }
  }
};

export function login(aEmail, aPassword, aLocale, aOtp, aPhoneNumber, aSignInToken){
  let user = {email:aEmail, locale:aLocale, password:aPassword};
  let loginPath = '/api/sessions/login';
  
  if(aPhoneNumber){
    user["primary_contact_number"] = aPhoneNumber;
  }
  if(aOtp){
    user["otp_attempt"] = aOtp;
  }
  let data = {user:user, hsc_user:true};
  
  if(aSignInToken){
    data = {user:user, hsc_user:true, sign_in_token:aSignInToken};
    loginPath = '/api/sessions/login_with_sign_in_token';
  }
  return (dispatch) => {
    return axios.post(endPoint() + loginPath, data, {withCredentials:true}).then((newResult) => {
      EventTracker.track(EventTypes.LOGIN);
      let newUser = newResult.data;
      
      if(newUser && newUser.locale && ['de', 'en', 'es', 'fr', 'it'].includes(newUser.locale)){
        i18n.changeLanguage(newUser.locale).then((newResult) => {
          setDefaultLocale(newUser.locale);
          moment.locale(newUser.locale);
        });
      }
      dispatch({type:LOGIN, result:newUser});
      redirectUser(newUser, dispatch);
    }, (newError) => {
      dispatch({type:LOGIN_ERROR, result:newError.response && newError.response.data});
    });
  };
}

export function resetPassword({password, passwordConfirmation, resetPasswordToken}){
  return (dispatch) => {
    return axios.put(endPoint() + "/api/users/password", {
      user:{password:password, reset_password_token:resetPasswordToken, password_confirmation:passwordConfirmation},
      hsc_user:true
    }).then(() => {
      dispatch({type:RESET_PASSWORD, result:{message:'Password reset successfully. Redirecting to login screen in 5 secs.', success:true}});
      setTimeout(() => {
        dispatch(push('/login'));
      }, 5000);
    }, (newError) => {
      dispatch({type:RESET_PASSWORD_ERROR, result:newError.response && newError.response.data});
    });
  };
}

export function forgotPassword({email}){
  return (dispatch) => {
    let promise = axios.post(endPoint() + "/api/users/password", {user:{email:email}, hsc_user:true});
    promise.then(() => {
      dispatch({type:FORGOT, result:{message:'Password reset instructions sent to email for further action. Redirecting to login screen in 5 secs.'}});
      setTimeout(() => {
        dispatch(push('/login'));
        dispatch({type:FORGOT, result:{message:''}});
      }, 5000);
    }, (newError) => {
      dispatch({type:FORGOT_ERROR, result:newError.response && newError.response.data});
    });
    return promise;
  };
}

export function updateCurrentUser(user) {
  EventTracker.track(EventTypes.UPDATE_USER);
  let query = `
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      user {
        id
        first_name
        last_name
        email
        subject_status
        search_preference {
          query
          preferred_language
          alert_type
          disposition_code
          virtual_appointment_date
        }
      }
    }
  }
  `;
  let variables = { updateUserInput:user };
  let operationName = `UpdateUser`;
  
  return (dispatch) => {
    return global.api.post("/queries",
      { query, variables, operationName }
    )
    .then((result) => {
      dispatch({
        type: UPDATE_CURRENT_USER_ERROR,
        result: {}
      });
      return true
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_CURRENT_USER_ERROR,
        result: error.data.error
      });
      return error;
    })
  }
}

export function updateCompanyLogoImage(file2Upload, aThemeId){
  return (dispatch) => {
    const file = new FormData();
    file.append('file', file2Upload, file2Upload.name);
    
    return global.api.post("/api/images?type=theme_logo&id=" + aThemeId, file, {
      headers:{
        'Content-Type':`multipart/form-data; boundary=${file._boundary}`
      },
      timeout:30000
    });
  };
}

export function updateCurrentUserImage(file2Upload){
  return (dispatch) => {
    const file = new FormData();
    file.append('file', file2Upload, file2Upload.name);
    
    return global.api.post("/api/images?type=profile", file, {
      headers:{
        'Content-Type':`multipart/form-data; boundary=${file._boundary}`
      },
      timeout:30000,
    });
  };
}

export function loadUser(){
  return (dispatch) => {
    return global.api.get(endPoint() + '/api/sessions/current').then((newResult) => {
      if(newResult.status === 200){
        let currentUser = newResult.data.user;
        dispatch({type:LOAD_CURRENT_USER, result:currentUser});
        
        if(window.location.pathname === '/login'){
          redirectUser(currentUser, dispatch);
        }
      }
    }, (newError) => {
    
    });
  };
}

export function findUserById(aId){
  let query = `
      query findUserById($id: ID!) {
        user(id: $id) {
          id
          first_name
          last_name
          email
        }
      }
    `;
  let variables = {id:aId};
  
  return (dispatch) => {
    return global.api.post("/queries", {query, variables});
  };
}

export function signup({user}){
  EventTracker.track(EventTypes.SIGNUP);
  let query = `
  mutation AddCreator($addCreatorInput: AddCreatorInput!) {
    addCreator (input: $addCreatorInput) {
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
  `;
  let variables = {addCreatorInput:{user:user}};
  let operationName = `AddCreator`;
  return (dispatch) => {
    return global.api.post("/queries", {query:query, variables:variables, operationName:operationName}).then(() => {
      dispatch(login({email:user.email, password:user.password}));
    }, (newError) => {
      dispatch({type:SIGNUP_ERROR, result:newError.data});
    });
  };
}

export function logout(){
  i18n.changeLanguage('en').then(() => {
    setDefaultLocale('en');
    moment.locale('en');
  });
  localStorage.removeItem('tableData');
  localStorage.removeItem('dynamicFields');
  return (dispatch) => {
    return axios.get(endPoint() + '/api/sessions/logout', {withCredentials:true}).then((newResult) => {
      localStorage.clear();
      EventTracker.track(EventTypes.LOGOUT);
      dispatch({type:LOGOUT});
      setTimeout(() => dispatch(push('/login')), 5000);
    }, (newError) => {
      dispatch({type:LOGOUT, error:newError});
    });
  };
}

export function setCurrentPatient(patient){
  return (dispatch) => {
    dispatch({type:SET_CURRENT_PATIENT, patient:patient});
  };
}

// export function setCurrentConversation(conversation) {
//   return dispatch => {
//     dispatch({
//       type: SET_CURRENT_CONVERSATION,
//       data: conversation
//     });
//     if (conversation && conversation.id) {
//       dispatch(fetchMessages(conversation.id))
//     }
//   };
// }

// export function setCurrentMessages(messages) {
//   return dispatch => {
//     dispatch({
//       type: SET_CURRENT_MESSAGES,
//       data: messages
//     });
//   };
// }

export function clearCurrentPatient() {
  return dispatch => {
    dispatch({
      type: LOAD_PATIENT,
      data: {
        gender: null,
        birth_year: null,
        email: null
      }
    });
    dispatch({
      type: LOAD_PATIENT_DATA,
      data: {}
    });
    dispatch({
      type: LOAD_PATIENT_ERROR,
      error: null
    });
  };
}

export function clearSyncedDevicesData() {
  return dispatch => {
    dispatch({
      type: LOAD_SYNCED_DEVICE_DATA,
      data: {}
    });
  };
}

export function setPermittedItems(data) {
  return dispatch => {
    dispatch({
      type: PERMITTED_ITEMS,
      payload: data
    });
  };
}
