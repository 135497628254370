import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import TabBar from "../../Custom UI/TabBar/TabBar";
import PaymentAccount from "../../Components/PaymentAccount/PaymentAccount";
import PaymentsList from "../../Lists/PaymentsList/PaymentsList";
import PaymentTriggersList from "../../Lists/PaymentTriggersList/PaymentTriggersList";
import {Translation} from "react-i18next";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import PaymentTrigger from "../../Components/PaymentTrigger/PaymentTrigger";
import './ManagePayments.scss';

export default class ManagePayments extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      tabBarKey:'TRIGGERS',
      paymentTriggers:[],
      totalPaymentTriggers:0
    }
  }
  
  // Methods
  
  
  
  // Render
  
  render() {
    const {company, createPaymentTrigger, fetchQuestionsForSurveyById} = this.props;
    const {tabBarKey, paymentTriggers, totalPaymentTriggers} = this.state;
    
    return (
      <div className="main-panel-inner-container">
        <SecondaryHeader title={<Translation>{ (t) => t('PAYMENTS') }</Translation>}/>
        
        <div className="main-panel-inner-container-secondary">
          <TabBar tabBarTabsArray={[{key:'TRIGGERS'}, {key:'PAYMENTS'}, {key:'NEW_TRIGGER'}, {key:'ACCOUNT', badge:(company && !company.has_valid_hyperwallet_credentials && !company.has_valid_tremendous_credentials ? -1 : 0)}]}
                  initialSelectedTabKey={'TRIGGERS'}
                  selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
          />
          
          {tabBarKey === 'TRIGGERS' ?
            <div className="manage-payments-trigger-container">
              <div className="manage-payments-trigger-text">
                <Translation>{ (t) => t('TRIGGERS_EXPLANATION') }</Translation>
              </div>
              
              <PaymentTriggersList paymentTriggers={paymentTriggers}
                                   totalPaymentTriggers={totalPaymentTriggers}
                                   fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                                   {...this.props}
              />
            </div>
            :
            null
          }
          
          {tabBarKey === 'PAYMENTS' ?
            <div className="manage-payments-container">
              <PaymentsList explanationKey={'PAYMENTS_EXPLANATION'}
                            {...this.props}/>
            </div>
            :
            null
          }
          
          {tabBarKey === 'NEW_TRIGGER' ?
            <div className="manage-payments-new-trigger-container">
              <div className="manage-payments-new-trigger-text">
                <Translation>{ (t) => t('NEW_TRIGGER_EXPLANATION') }</Translation>
              </div>
              
              <PaymentTrigger className={'payment-trigger-large'}
                              company={company}
                              createPaymentTrigger={createPaymentTrigger}
                              fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                              {...this.props}
              
              />
            </div>
            :
            null
          }
          
          {tabBarKey === 'ACCOUNT' ?
            <div className="manage-payment-account-container">
              <PaymentAccount {...this.props}
              />
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}
