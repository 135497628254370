import React from 'react';
import './Schedule.scss';
import Edit from '@material-ui/icons/Edit';
import Info from "../../Custom UI/Info/Info";
import Send from '@material-ui/icons/Send';
import GetApp from '@material-ui/icons/GetApp';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import {toast} from "react-toastify";
import i18next from "i18next";
import Timeline from '@material-ui/icons/Timeline';
import DynamicList from "../../Lists/DynamicList/DynamicList";
import GroupsModal from "../../Modals/GroupsModal/GroupsModal";
import SurveyDrawer from "../SurveyDrawer/SurveyDrawer";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import AddSurveyModal from "../../Modals/AddSurveyModal/AddSurveyModal";
import LabeledDropdown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {formatUserName} from "../../Helpers/AdminHelpers";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import PaymentTriggerModal from "../../Modals/PaymentTriggerModal/PaymentTriggerModal";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ScheduleTriggerModal from "../../Modals/ScheduleTriggerModal/ScheduleTriggerModal";
import ScheduleVersionsModal from "../../Modals/ScheduleVersionsModal/ScheduleVersionsModal";
import {downloadStringToFile, errorMessageFromServerError} from "../../Helpers/Helpers";
import LabeledMultipleSelectDropDown from "../../Custom UI/LabeledInputs/LabeledMultipleSelectDropDown/LabeledMultipleSelectDropDown";
import {ScheduleColours, ScheduleSections, ScheduleTriggerConditionEventTypes, ScheduleTriggerTypes} from "../../constants/ScheduleTriggerTypes";
import {detailViewForTrigger, detailViewForPaymentTrigger, translationKeyForTriggerType, scheduleVersionNumber} from "../../Helpers/ScheduleHelpers";
import Loading from "../../Custom UI/Loading/Loading";
import PublishScheduleModal from "../../Modals/PublishScheduleModal/PublishScheduleModal";

const notifyScheduleSaveError = (aError) => toast(i18next.t('SCHEDULE_SAVE_ERROR', {aError:aError}), NOTIFY_OPTS.autoClose);
const notifyScheduleSaveSuccess = () => toast(i18next.t('SCHEDULE_SAVED'), NOTIFY_OPTS.autoClose);
const notifySchedulePublishError = (aError) => toast(i18next.t('SCHEDULE_PUBLISH_ERROR', {aError:aError}), NOTIFY_OPTS.autoClose);
const notifySchedulePublishSuccess = () => toast(i18next.t('SCHEDULE_PUBLISHED'), NOTIFY_OPTS.autoClose);

export default class Schedule extends React.Component {
  
  // Instance Variables
  
  uploadJSONInputRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      saving:false,
      editing:true,
      loading:false,
      triggerEditable:true,
      trigger:null,
      schedule:{
        id:null,
        name:'',
        draft_schedule_version:{
          groups:[],
          payment_triggers:[],
          additional_triggers:[],
          survey_version_collections:[],
          dependent_survey_version_collections:[],
          dependency_survey_version_collections:[]
        }
      },
      surveyId:null,
      triggerType:ScheduleTriggerTypes.general,
      paymentTrigger:null,
      selectedColumn:null,
      selectedSurvey:null,
      groupsModalOpen:false,
      scheduleVersion:null,
      downloadSchedule:false,
      selectedResponse:null,
      addSurveyModalOpen:false,
      isSurveyDrawerOpen:false,
      confirmPublishModalOpen:false,
      paymentTriggerModalOpen:false,
      scheduleTriggerModalOpen:false,
      showAdditionTriggerDetails:false,
      confirmRemoveSurveyModalOpen:false
    };
    if(props.schedule && props.schedule.id && props.fetchSchedule){
      this.state.schedule.id = props.schedule.id;
      this.state.schedule.name = props.schedule.name;
      
      props.fetchSchedule(props.schedule.id).then((newResult) => {
        this.setSchedule(newResult.data.data.schedule);
      }, (newError) => {
      
      });
    }
  }
  
  componentDidMount(){
    const {schedule, fetchSchedule} = this.props;
    
    if(schedule && schedule.id && fetchSchedule){
      this.setState({saving:true, loading:true});
      fetchSchedule(schedule.id).then((newResult) => {
        this.setState({saving:false, loading:false, reportTemplate:newResult.data.data.schedule});
      }, (newError) => {
        this.setState({saving:false, loading:false});
      });
    }
  }
  
  // Methods
  
  removeArrayFromArray(aArray, aRemoveArray, aComparisonProperty = 'id'){
    let returnValue = [];
    
    if(Array.isArray(aArray) && Array.isArray(aRemoveArray)){
      for(let object of aArray){
        let duplicate = true;
        
        for(let removeObject of aRemoveArray){
          if(removeObject[aComparisonProperty] === object[aComparisonProperty]){
            duplicate = false;
            break;
          }
        }
        if(duplicate){
          returnValue.push(object);
        }
      }
    }
    return returnValue;
  }
  
  uploadJSON(aEvent){
    if(aEvent && aEvent.target && aEvent.target.files && aEvent.target.files.length > 0){
      const reader = new FileReader();
      reader.readAsText(aEvent.target.files[0]);
      
      reader.onload = (aFileReadEvent) => {
        let {schedule} = this.state;
        let newSchedule = JSON.parse(aFileReadEvent.target.result);
        
        let oldSurveys = schedule && schedule.draft_schedule_version && schedule.draft_schedule_version.survey_version_collections ? schedule.draft_schedule_version.survey_version_collections : [];
        let newSurveys = newSchedule && newSchedule.draft_schedule_version && newSchedule.draft_schedule_version.survey_version_collections ? newSchedule.draft_schedule_version.survey_version_collections : [];
        
        let surveysToRemove = this.removeArrayFromArray(oldSurveys, newSurveys);
        let surveysToAdd = this.removeArrayFromArray(newSurveys, oldSurveys);
        
        let oldGroups = schedule && schedule.draft_schedule_version && schedule.draft_schedule_version.groups ? schedule.draft_schedule_version.groups : [];
        let newGroups = newSchedule && newSchedule.draft_schedule_version && newSchedule.draft_schedule_version.groups ? newSchedule.draft_schedule_version.groups : [];
        
        let groupsToRemove = this.removeArrayFromArray(oldGroups, newGroups);
        let groupsToAdd = this.removeArrayFromArray(newGroups, oldGroups);
        
        let triggersToAdd = [];
        
        if(newSchedule && newSchedule.draft_schedule_version){
          for(let trigger of newSchedule.draft_schedule_version.additional_triggers){
            let newTrigger = {...trigger};
            delete newTrigger['id'];
            triggersToAdd.push(newTrigger);
          }
          if(newSchedule.draft_schedule_version.survey_version_collections){
            for(let survey of newSchedule.draft_schedule_version.survey_version_collections){
              for(let key of ['end_triggers', 'start_triggers', 'additional_triggers']){
                for(let trigger of survey[key]){
                  let newTrigger = {...trigger};
                  delete newTrigger['id'];
                  triggersToAdd.push(newTrigger);
                }
              }
            }
          }
        }
        
        let triggersToRemove = []; // TODO: Needed?
        this.updateSchedule(surveysToAdd, surveysToRemove, groupsToAdd, groupsToRemove, triggersToAdd, triggersToRemove);
      }
    }
  }
  
  downloadJSON(){
    const {schedule} = this.state;
    downloadStringToFile(JSON.stringify(schedule), schedule.name);
  }
  
  addView = (aClick, aTitleKey) => {
    const {schedule} = this.state;
    
    return (
      <div className="schedule-add-view"
           onClick={schedule && schedule.id && schedule.id.length > 0 ? aClick : undefined}>
        <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
        
        {i18next.t(aTitleKey)}
      </div>
    );
  };
  
  titleView = (aClick, aTitleKey) => {
    const {schedule} = this.state;
    // TODO: Add left and right buttons.
    
    return (
      <div className="schedule-title-view">
        {i18next.t(aTitleKey)}
        
        <div className="schedule-title-delete-view"
             onClick={schedule && schedule.id && schedule.id.length > 0 ? aClick : undefined}>
          X
        </div>
      </div>
    );
  };
  
  surveyView = (aSurvey) => {
    let info = '';
    
    if(aSurvey && aSurvey.dependent_survey_version_collections && aSurvey.dependent_survey_version_collections.length > 0){
      info = i18next.t('SURVEYS') + ':\n\n';
      
      for(let index = 0; index < aSurvey.dependent_survey_version_collections.length; index += 1){
        let survey = aSurvey.dependent_survey_version_collections[index];
        info += survey.name;
        
        if(index < aSurvey.dependent_survey_version_collections.length - 1){
          info += '\n';
        }
      }
    }
    
    return (
      <div className="schedule-survey-view">
        <div className="schedule-survey-view-title">
          {i18next.t(aSurvey.name)}
        </div>
        
        {info.length > 0 ?
          <Info message={info}
                popoverPlacement={'right'}
          />
          :
          null
        }
      </div>
    );
  };
  
  triggerView = (aTitleKey, aDescriptionKey, aIndex = null) => {
    return (
      <div className="schedule-trigger-view">
        <div className="schedule-trigger-view-title">
          {i18next.t(aTitleKey) + (aIndex !== null ? (' ' + aIndex.toString()) : '')}
        </div>
  
        {aDescriptionKey ?
          <div className="schedule-trigger-view-description">
            {i18next.t(aDescriptionKey)}
          </div>
          :
          null
        }
      </div>
    );
  };
  
  indicatorView = (aClick, aColour, aAddClick = undefined, aCount = 1) => {
    const count = Math.max(aCount, 1);
    
    return (
      aColour && aColour.length > 0 ?
        <div className="schedule-indicator-container"
             onClick={count === 1 ? aClick : undefined}>
          {aAddClick ?
            <div className="schedule-indicator-hidden"/>
            :
            null
          }
          
          {[...Array(count)].map((x, index) =>
            <div className="schedule-indicator"
                 style={{backgroundColor:aColour}}
                 onClick={count > 1 ? () => aClick(index) : undefined}
            />
          )}
          
          {aAddClick ?
            <div className="schedule-indicator-add-view"
                 style={{backgroundColor:aColour}}
                 onClick={(aEvent) => {
                   aEvent.preventDefault()
                   aEvent.stopPropagation();
                   aAddClick();
                 }}>
              +
            </div>
            :
            null
          }
        </div>
        :
        null
    );
  };
  
  updateSchedule = (aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove) => {
    const {saveSchedule} = this.props;
    const {saving, schedule} = this.state;
    
    if(!saving && schedule && schedule.name && schedule.name.length > 0){
      this.setState({saving:true}, () =>
        saveSchedule(schedule.id, schedule.name, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove).then((newResult) => {
          this.setSchedule(newResult.data.data.saveSchedule.schedule);
          this.setState({saving:false});
          notifyScheduleSaveSuccess();
        }, (newError) => {
          notifyScheduleSaveError(errorMessageFromServerError(newError));
          this.setState({saving:false});
        })
      );
    }
  }
  
  // TODO: This is not sufficient in its current state, as the Trigger could change which Survey it's in, it's type,
  //       or it's function, and it would have to match the server's queries on where to add it after create/update.
  //       For now, we are simply re-fetching the Schedule when it completes, but this can be expanded in the future.
  addTriggerToSchedule = (aTrigger) => {
    const {schedule} = this.state;
    
    let replacedNewTrigger = false;
    
    for(let index = 0; index < schedule.draft_schedule_version.survey_version_collections.length; index += 1){
      const surveyVersionCollection = schedule.draft_schedule_version.survey_version_collections[index];
      
      if(Array.isArray(surveyVersionCollection.additional_triggers) && surveyVersionCollection.additional_triggers.length > 0){
        for(let triggerIndex = 0; triggerIndex < surveyVersionCollection.additional_triggers.length; triggerIndex += 1){
          const trigger = surveyVersionCollection.additional_triggers[triggerIndex];
          
          if(trigger.id === aTrigger.id){
            schedule.draft_schedule_version.survey_version_collections[index].additional_triggers[triggerIndex] = aTrigger;
            replacedNewTrigger = true;
            break;
          }
        }
        if(replacedNewTrigger){
          this.setSchedule({...schedule});
          break;
        }
      }
    }
    if(replacedNewTrigger === false){
      // TODO: Add it to the right column, and right type.
    }
  }
  
  setSchedule = (aSchedule, aCallback) => {
    this.setState({schedule:aSchedule}, () => {
      const {didUpdateSchedule} = this.props;
      
      if(didUpdateSchedule){
        didUpdateSchedule(aSchedule);
      }
      if(aCallback){
        aCallback();
      }
    });
  }
  
  // Render
  
  render(){
    const {
      user,
      company,
      patient,
      languages,
      fetchSchedule,
      publishSchedule,
      saveScheduleTrigger,
      createPaymentTrigger,
      updatePaymentTrigger,
      removeScheduleTrigger,
      fetchPatientsInSchedule,
      fetchAppAdditionalColumns,
      fetchQuestionsForSurveyById,
      fetchSurveyVersionCollections,
      fetchScheduleVersionsForSchedule,
      fetchQuestionsForSurveyByReference,
      showCancel = true,
      presentedInsideParentModal = false
    } = this.props;
    const {
      saving,
      editing,
      loading,
      trigger,
      schedule,
      surveyId,
      triggerType,
      paymentTrigger,
      selectedColumn,
      selectedSurvey,
      groupsModalOpen,
      scheduleVersion,
      searchedPatient,
      downloadSchedule,
      selectedResponse,
      addSurveyModalOpen,
      isSurveyDrawerOpen,
      confirmPublishModalOpen,
      paymentTriggerModalOpen,
      scheduleTriggerModalOpen,
      scheduleVersionsModalOpen,
      showAdditionTriggerDetails,
      confirmRemoveSurveyModalOpen,
      triggerEditable = true
    } = this.state;
    
    const role = roleForCompany(company);
    const editPermissions = ['admin', 'admin_level_1', 'admin_level_4', 'admin_level_10'];
  
    const legendArray = [
      {colour:ScheduleColours.completed, labelKey:'COMPLETED'},
      {colour:ScheduleColours.incomplete, labelKey:'INCOMPLETE'},
      {colour:ScheduleColours.missed, labelKey:'MISSED'},
      {colour:ScheduleColours.surveyEnding, labelKey:'SURVEY_ENDING'},
      {colour:ScheduleColours.upcoming, labelKey:'UPCOMING'}
    ];
    
    let groupOptions = [];
    
    if(company && company.core_group && company.core_group.id && company.core_group.id.length > 0){
      groupOptions.push({label:i18next.t('CORE_GROUP'), value:company.core_group.id});
    }
    for(let group of company.groups){
      groupOptions.push({label:group.name, value:group.id});
    }
    
    let selectedGroupOptions = [];
    
    for(let group of schedule.draft_schedule_version.groups){
      selectedGroupOptions.push({label:group.name, value:group.id});
    }
    
    const sectionHeadersArray = [
      {name:'SETTINGS'},
      {name:'ATTACHED'},
      {name:'ADDITIONAL_TRIGGERS', totalOffset:(editing ? -1 : 0), showDetails:showAdditionTriggerDetails, showDetailsChanged:(aShowDetails) => this.setState({showAdditionTriggerDetails:aShowDetails})},
      {name:'PAYMENTS', totalOffset:(editing ? -1 : 0)},
      {name:'SURVEY_TRIGGERS'}
    ];
    
    let columnsArray = [{key:'blank', customTitle:' ', columnWeight:1, columnNameKey:'blank', propertiesArray:['name'],
      csvFormatter:(aSurvey, aRow, aSection) => {
        let returnValue = '';
  
        if(aSection === ScheduleSections.settings){
          returnValue = i18next.t(aSurvey.name) + ': ' + i18next.t(aSurvey.description);
        }
        else if(aSection === ScheduleSections.attached){
          returnValue = i18next.t(aSurvey.name);
        }
        else if(aSection === ScheduleSections.additionalTriggers){
          if(aSurvey.name === 'ADD'){
            returnValue = '';
          }
          else{
            returnValue = i18next.t(aSurvey.name) + (aSurvey.index ? (' ' + aSurvey.index) : '') + (aSurvey.description ? ': ' + i18next.t(aSurvey.description) : '');
          }
        }
        else if(aSection === ScheduleSections.paymentTriggers){
          if(aSurvey.name === 'ADD'){
            returnValue = '';
          }
          else{
            returnValue = i18next.t(aSurvey.name) + (aSurvey.description ? ': ' + i18next.t(aSurvey.description) : '');
          }
        }
        else if(aSection === ScheduleSections.surveyTriggers){
          returnValue = i18next.t(aSurvey.name) + (aSurvey.description ? ': ' + i18next.t(aSurvey.description) : '');
        }
        return returnValue;
      },
      templateCell:(aSurvey, aRow, aSection) => {
        let returnValue = null;
        
        if(aSection === ScheduleSections.settings){
          returnValue = this.triggerView(aSurvey.name, aSurvey.description);
        }
        else if(aSection === ScheduleSections.attached){
          returnValue = this.surveyView(aSurvey);
        }
        else if(aSection === ScheduleSections.additionalTriggers){
          if(aSurvey.name === 'ADD'){
            returnValue = this.addView(() => this.setState({scheduleTriggerModalOpen:true}), 'ADD_NEW_TRIGGER');
          }
          else{
            returnValue = this.triggerView(aSurvey.name, aSurvey.description, aSurvey.index);
          }
        }
        else if(aSection === ScheduleSections.paymentTriggers){
          if(aSurvey.name === 'ADD'){
            // TODO: What does this show?
            returnValue = this.addView(() => this.setState({paymentTriggerModalOpen:true}), 'ADD_NEW_PAYMENT_TRIGGER');
          }
          else{
            returnValue = this.triggerView(aSurvey.name, aSurvey.description);
          }
        }
        else if(aSection === ScheduleSections.surveyTriggers){
          returnValue = this.triggerView(aSurvey.name, aSurvey.description);
        }
        return returnValue;
      }
    }];
    
    if(schedule.draft_schedule_version.additional_triggers && schedule.draft_schedule_version.additional_triggers.length > 0){
      columnsArray.push({key:'additional_triggers', customTitle:i18next.t('ADDITIONAL_TRIGGERS'), columnWeight:1, columnNameKey:'additional_triggers', propertiesArray:['name'],
        csvFormatter:(aSurvey, aRow, aSection) => {
        let returnValue = '';
        
        if(aSection === ScheduleSections.additionalTriggers){
          if(aSurvey.type === ScheduleTriggerConditionEventTypes.added_to_group){
            returnValue = 'X';
          }
          else if(aSurvey.name === 'ADD'){
            returnValue = '';
          }
          else{
            returnValue = i18next.t(aSurvey.name) + (aSurvey.index !== null ? (' ' + aSurvey.index) : '') + (aSurvey.description ? ': ' + i18next.t(aSurvey.description) : '');
          }
        }
        return returnValue;
      },
        templateCell:(aSurvey, aRow, aSection) => {
        let returnValue = null;
        
        if(aSection === ScheduleSections.additionalTriggers){
          if(aSurvey.type === ScheduleTriggerConditionEventTypes.added_to_group){
            returnValue = this.indicatorView((aIndex) => {
              this.setState({
                trigger:schedule.draft_schedule_version.additional_triggers.length === 1 ? schedule.draft_schedule_version.additional_triggers[0] : schedule.draft_schedule_version.additional_triggers[aIndex],
                triggerType:triggerType,
                scheduleTriggerModalOpen:true
              })
            }, ScheduleColours.included, (aIndex) => this.setState({
              trigger:null,
              triggerType:triggerType,
              scheduleTriggerModalOpen:true
            }), schedule.draft_schedule_version.additional_triggers.length);
          }
        }
        return returnValue;
      }
      });
    }
    
    let surveysArray = [];
    let settingsArray = [];
    let triggersArray = [];
    let fixedObjectsArray = [];
    let nestedSurveysArray = [];
    let surveyTriggersArray = [];
    let paymentTriggersArray = [];
    
    let endTriggers = {name:'END_TRIGGER', description:'END_TRIGGER_DESCRIPTION'};
    let startTriggers = {name:'START_TRIGGER', description:'START_TRIGGER_DESCRIPTION'};
    
    const currentPatient = patient ? patient : (searchedPatient ? searchedPatient : null);
    
    if(schedule && schedule.draft_schedule_version){
      for(let surveyVersionCollection of schedule.draft_schedule_version.survey_version_collections){
        surveysArray.push(surveyVersionCollection);
        columnsArray.push({key:surveyVersionCollection.reference, columnWeight:1,
          columnNameKey:surveyVersionCollection.reference, propertiesArray:[surveyVersionCollection.reference],
          customTitle:surveyVersionCollection.name,
          customTitleView:this.titleView(() => this.setState({selectedSurvey:surveyVersionCollection, confirmRemoveSurveyModalOpen:true}), surveyVersionCollection.name),
          csvFormatter:(aSurvey, aRow, aSection) => {
            let returnValue = '';
            let trigger = aSurvey[surveyVersionCollection.reference];
  
            if(trigger){
              if(aSection === ScheduleSections.settings){
                returnValue = 'X';
              }
              else if(aSection === ScheduleSections.attached){
                returnValue = 'X';
              }
              else if(aSection === ScheduleSections.additionalTriggers){
                returnValue = 'X';
              }
              else if(aSection === ScheduleSections.paymentTriggers){
                returnValue = 'X';
              }
              else if(aSection === ScheduleSections.surveyTriggers){
                returnValue = 'X';
              }
            }
            return returnValue;
          },
          templateCell:(aSurvey, aRow, aSection) => {
            let returnValue = null;
            let trigger = aSurvey[surveyVersionCollection.reference];
            
            if(aSection === ScheduleSections.settings){
              let triggerType = [ScheduleTriggerTypes.start, ScheduleTriggerTypes.end][aRow];
              
              // TODO: Fix text for triggers.
              // if(aSurvey[surveyVersionCollection.reference] !== undefined){
              //   if(aRow === 0){
              //     returnValue = detailViewForStartTrigger(aSurvey[surveyVersionCollection.reference], () => this.setState({trigger:aSurvey, triggerType:ScheduleTriggerTypes.start, scheduleTriggerModalOpen:true}));
              //   }
              //   else{
              //     returnValue = detailViewForEndTrigger(aSurvey[surveyVersionCollection.reference], () => this.setState({trigger:aSurvey, triggerType:ScheduleTriggerTypes.end, scheduleTriggerModalOpen:true}));
              //   }
              // }
              // else{
              //   if(aRow === 0){
              //     returnValue = detailViewForStartTrigger(null, () => this.setState({trigger:aSurvey, triggerType:ScheduleTriggerTypes.start, scheduleTriggerModalOpen:true}));
              //   }
              //   else{
              //     returnValue = detailViewForEndTrigger(null, () => this.setState({trigger:aSurvey, triggerType:ScheduleTriggerTypes.end, scheduleTriggerModalOpen:true}));
              //   }
              // }
              if(trigger && trigger.length > 0){
                returnValue = this.indicatorView((aIndex) => {
                  this.setState({
                    trigger:trigger.length === 1 ? trigger[0] : trigger[aIndex],
                    triggerType:triggerType,
                    surveyId:surveyVersionCollection.reference,
                    scheduleTriggerModalOpen:true
                  })
                }, ScheduleColours.included, (aIndex) => this.setState({trigger:null, triggerType:triggerType, surveyId:surveyVersionCollection.reference, scheduleTriggerModalOpen:true}), trigger.length);
              }
              else{
                returnValue = this.indicatorView(() => this.setState({trigger:null, triggerType:triggerType, surveyId:surveyVersionCollection.reference, scheduleTriggerModalOpen:true}), ScheduleColours.empty);
              }
            }
            else if(aSection === ScheduleSections.attached){
              let colour = null;
              let click = null;
              
              if(trigger){
                if(currentPatient){
                  if(trigger.survey_responses){
                    if(trigger.survey_responses.length > 0){
                      colour = ScheduleColours.completed;
                      click = () => this.setState({selectedSurvey:trigger, selectedResponse:trigger.survey_responses[0], isSurveyDrawerOpen:true});
                    }
                  }
                }
                else{
                  colour = ScheduleColours.included;
                }
              }
              returnValue = this.indicatorView(click, colour);
            }
            else if(aSection === ScheduleSections.additionalTriggers){
              if(trigger){
                if(Array.isArray(trigger)){
                  trigger = trigger[aSurvey.index !== null && aSurvey.index !== undefined ? aSurvey.index - 1 : 0];
                }
                if(currentPatient){
                  // TODO: Indicate if the trigger has fired for the current patient.
                }
                else{
                  if(showAdditionTriggerDetails){
                    returnValue = detailViewForTrigger(trigger, () => this.setState({trigger:trigger, surveyId:surveyVersionCollection.reference, triggerType:ScheduleTriggerTypes.general, scheduleTriggerModalOpen:true}));
                  }
                  else{
                    returnValue = this.indicatorView(() => this.setState({trigger:trigger, surveyId:surveyVersionCollection.reference, triggerType:ScheduleTriggerTypes.general, scheduleTriggerModalOpen:true}), ScheduleColours.included);
                  }
                }
              }
              else{
                returnValue = detailViewForTrigger(null, () => this.setState({trigger:null, surveyId:surveyVersionCollection.reference, triggerType:ScheduleTriggerTypes.general, scheduleTriggerModalOpen:true}));
              }
            }
            else if(aSection === ScheduleSections.paymentTriggers){
              if(trigger){
                if(currentPatient){
                  // TODO: Message if paid or not.
                }
                else{
                  // TODO: Show number_of_payments in an indicator.
                  if(showAdditionTriggerDetails){
                    returnValue = detailViewForPaymentTrigger(trigger, () => this.setState({paymentTrigger:aSurvey, selectedSurvey:surveyVersionCollection, paymentTriggerModalOpen:true}));
                  }
                  else{
                    returnValue = this.indicatorView(() => this.setState({paymentTrigger:trigger, selectedSurvey:surveyVersionCollection, paymentTriggerModalOpen:true}), ScheduleColours.included);
                  }
                }
              }
              else{
                returnValue = detailViewForPaymentTrigger(null, () => this.setState({paymentTrigger:null, selectedSurvey:surveyVersionCollection, paymentTriggerModalOpen:true}));
              }
            }
            else if(aSection === ScheduleSections.surveyTriggers){
              if(trigger){
                if(currentPatient){
                
                }
                else{
                  if(showAdditionTriggerDetails){
                    returnValue = detailViewForTrigger(trigger, () => this.setState({trigger:trigger, triggerEditable:false, scheduleTriggerModalOpen:true}));
                  }
                  else{
                    returnValue = this.indicatorView(() => this.setState({trigger:trigger, triggerEditable:false, scheduleTriggerModalOpen:true}), ScheduleColours.included);
                  }
                }
              }
              else{
                returnValue = detailViewForTrigger(null, () => this.setState({trigger:trigger, triggerEditable:false, scheduleTriggerModalOpen:true}));
              }
            }
            return returnValue;
          }});
        
        if(surveyVersionCollection.start_triggers){
          startTriggers[surveyVersionCollection.reference] = surveyVersionCollection.start_triggers;
        }
        if(surveyVersionCollection.end_triggers){
          endTriggers[surveyVersionCollection.reference] = surveyVersionCollection.end_triggers;
        }
        if(surveyVersionCollection.dependency_survey_version_collections && surveyVersionCollection.dependency_survey_version_collections.length > 0){
          for(let dependentSurveyVersionCollection of surveyVersionCollection.dependency_survey_version_collections){
            let isNewRow = true;
            
            for(let nestedSurvey of nestedSurveysArray){
              if(nestedSurvey.name === dependentSurveyVersionCollection.name){
                isNewRow = false;
                nestedSurvey[surveyVersionCollection.reference] = true;
                break;
              }
            }
            if(isNewRow){
              nestedSurveysArray.push({name:dependentSurveyVersionCollection.name, dependent_survey_version_collections:surveyVersionCollection.dependent_survey_version_collections, [surveyVersionCollection.reference]:true});
            }
          }
        }
        if(surveyVersionCollection.additional_triggers && surveyVersionCollection.additional_triggers.length > 0){
          for(let trigger of surveyVersionCollection.additional_triggers){
            let isNewRow = true;
            let type = trigger.condition_event;
            
            if(trigger.is_time_based){
              type = trigger.time_based_event;
            }
            for(let additionalTrigger of triggersArray){
              if(additionalTrigger.type === type){
                isNewRow = false;
                
                if(!Array.isArray(additionalTrigger[surveyVersionCollection.reference])){
                  additionalTrigger[surveyVersionCollection.reference] = [];
                }
                additionalTrigger[surveyVersionCollection.reference].push(trigger);
                break;
              }
            }
            if(isNewRow){
              triggersArray.push({name:translationKeyForTriggerType(type), type:type, [surveyVersionCollection.reference]:[trigger]});
            }
          }
        }
        
        if(surveyVersionCollection.payment_triggers && surveyVersionCollection.payment_triggers.length > 0){
          for(let index = 0; index < surveyVersionCollection.payment_triggers.length; index += 1){
            let trigger = surveyVersionCollection.payment_triggers[index];
            
            if(index < paymentTriggersArray.length){
              paymentTriggersArray[index][surveyVersionCollection.reference] = trigger;
            }
            else{
              paymentTriggersArray.push({name:i18next.t('PAYMENT_TRIGGER'), [surveyVersionCollection.reference]:trigger});
            }
          }
        }
        
        if(surveyVersionCollection.survey_triggers && surveyVersionCollection.survey_triggers.length > 0){
          for(let index = 0; index < surveyVersionCollection.survey_triggers.length; index += 1){
            let trigger = surveyVersionCollection.survey_triggers[index];
            
            if(index < surveyTriggersArray.length){
              surveyTriggersArray[index][surveyVersionCollection.reference] = trigger;
            }
            else{
              surveyTriggersArray.push({name:i18next.t('SURVEY_TRIGGERS'), [surveyVersionCollection.reference]:trigger});
            }
          }
        }
      }
      if(schedule.draft_schedule_version.additional_triggers && schedule.draft_schedule_version.additional_triggers.length > 0){
        triggersArray.push({name:translationKeyForTriggerType('added_to_group'), type:ScheduleTriggerConditionEventTypes.added_to_group});
      }
      if(triggersArray.length > 0){
        let orderedTriggersArray = [];
        
        for(let trigger of triggersArray){
          let maxTriggerCount = 1;
          
          for(let key of Object.keys(trigger)){
            if(['name', 'type'].includes(key) === false && Array.isArray(trigger[key])){
              maxTriggerCount = Math.max(maxTriggerCount, trigger[key].length);
            }
          }
          if(maxTriggerCount > 1){
            for(let index = 0; index < maxTriggerCount; index += 1){
              let newTrigger = {name:trigger.name, type:trigger.type, index:index + 1};
              
              for(let key of Object.keys(trigger)){
                if(['name', 'type'].includes(key) === false && Array.isArray(trigger[key]) && index < trigger[key].length){
                  newTrigger[key] = trigger[key];
                }
              }
              orderedTriggersArray.push(newTrigger);
            }
          }
          else{
            let newTrigger = {name:trigger.name, type:trigger.type};
            
            for(let key of Object.keys(trigger)){
              if(['name', 'type'].includes(key) === false && Array.isArray(trigger[key])){
                newTrigger[key] = trigger[key];
              }
            }
            orderedTriggersArray.push(newTrigger);
          }
        }
        triggersArray = orderedTriggersArray;
      }
      if(editing){
        triggersArray.push({name:'ADD'});
        paymentTriggersArray.push({name:'ADD'});
        columnsArray.push({key:'ADD_SURVEY', columnWeight:1, customTitleView:this.addView(() => this.setState({addSurveyModalOpen:true}), 'ADD_NEW_SURVEY')});
      }
      
      if(columnsArray.length < 5){
        for(let count = columnsArray.length; count <= 5; count += 1){
          columnsArray.push({key:'BLANK-' + count.toString(), columnWeight:1, customTitleView:<div></div>});
        }
      }
      
      settingsArray.push(startTriggers);
      settingsArray.push(endTriggers);
      
      fixedObjectsArray.push(settingsArray);
      fixedObjectsArray.push(nestedSurveysArray);
      fixedObjectsArray.push(triggersArray);
      fixedObjectsArray.push(paymentTriggersArray);
      fixedObjectsArray.push(surveyTriggersArray);
    }
    
    const disabled = !editing || (scheduleVersion !== null);
    
    // TODO: Check id scheduleVersion is working correctly.
    let version = scheduleVersionNumber(schedule);
    let status = i18next.t('NEW_SCHEDULE_DESCRIPTION');
    
    if(schedule && schedule.id && schedule.id.length > 0){
      status = i18next.t('DRAFT_DESCRIPTION', {aVersion:version});
      
      if(version === scheduleVersionNumber(schedule, false)){
        status = i18next.t('LIVE_DESCRIPTION', {aVersion:version});
      }
    }
    
    return (
      <div className={presentedInsideParentModal ? 'schedule-modal-container' : 'schedule-container'}>
        <div className="schedule-title">
          <div className="schedule-title-name-info">
            <div className="schedule-title-header">
              <LabeledDelayedInput className="schedule-name-input"
                                   value={schedule.name}
                                   handleSave={(aEvent) => {
                                     schedule.name = aEvent.target.value;
                                     this.setSchedule(schedule, () => this.updateSchedule());
                                   }}
                                   placeholder={i18next.t('SCHEDULE_NAME')}
              />
              
              {saving ?
                <div className="schedule-saving">
                  <Loading size={'tiny'}
                           showLabel={false}
                  />
                  
                  <div className="schedule-saving-title">
                    {i18next.t(loading ? 'LOADING' : 'SAVING')}
                  </div>
                </div>
                :
                <div className="schedule-title-explanation-text">
                  {status}
                </div>
              }
            </div>
            
            {!currentPatient && schedule && schedule.id && schedule.id.length > 0 ?
              <div className="schedule-editing-title-container">
                <div className="schedule-icon-button-container">
                  {editing ?
                    <div className="icon-button-container"
                         id={'schedule-publish-button'}
                         onClick={() => this.setState({confirmPublishModalOpen:true})}>
                      <div className="icon-button-circle">
                        <Send style={{color:'#2D81C9', fontSize:'25px'}}/>
                      </div>
                      
                      <div className="icon-button-container-text">
                        {i18next.t('PUBLISH')}
                      </div>
                    </div>
                    :
                    <div className="icon-button-container"
                         id={'schedule-version-button'}
                         onClick={() => this.setState({scheduleVersionsModalOpen:true})}>
                      <div className="icon-button-circle">
                        <Timeline style={{color:'#2D81C9', fontSize:'25px'}}/>
                      </div>
                      
                      <div className="icon-button-container-text">
                        {i18next.t('VERSION')}
                      </div>
                    </div>
                  }
                  
                  {!editing && schedule && schedule.id && schedule.id.length > 0 ?
                    <div className="icon-button-container"
                         id={'schedule-download-button'}
                         onClick={() => this.setState({downloadSchedule:true})}>
                      <div className="icon-button-circle">
                        <GetApp style={{color:'#2D81C9', fontSize:'25px'}}/>
                      </div>
                      
                      <div className="icon-button-container-text">
                        {i18next.t('CSV')}
                      </div>
                    </div>
                    :
                    null
                  }
                  
                  {schedule && schedule.id && schedule.id.length > 0 ?
                    <div className="icon-button-container"
                         id={'schedule-download-json-button'}
                         onClick={() => this.downloadJSON()}>
                      <div className="icon-button-circle">
                        <GetApp style={{color:'#2D81C9', fontSize:'25px'}}/>
                      </div>
                      
                      <div className="icon-button-container-text">
                        {i18next.t('DOWNLOAD')}
                      </div>
                    </div>
                    :
                    null
                  }
                  
                  {editing && schedule && schedule.id && schedule.id.length > 0 ?
                    <div className="icon-button-container"
                         id={'schedule-upload-json-button'}
                         onClick={() => this.uploadJSONInputRef.current.click()}>
                      <div className="icon-button-circle">
                        <ArrowUpward style={{color:'#2D81C9', fontSize:'25px'}}/>
                      </div>
                      
                      <div className="icon-button-container-text">
                        {i18next.t('UPLOAD')}
                      </div>
                      
                      <input type="file"
                             ref={this.uploadJSONInputRef}
                             onChange={(aEvent) => this.uploadJSON(aEvent)}
                             accept="txt"
                             style={{display:'none'}}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {editing ?
                    showCancel && schedule && schedule.id && schedule.id.length > 0 ?
                      <div className="icon-button-container"
                           id={'schedule-cancel-button'}
                           onClick={() => this.setState({editing:false})}>
                        <div className="icon-button-circle">
                          <CancelOutlinedIcon style={{color:'#2D81C9', fontSize:'25px'}}/>
                        </div>
    
                        <div className="icon-button-container-text">
                          {i18next.t('CANCEL')}
                        </div>
                      </div>
                      :
                      null
                    :
                    <div className="icon-button-container"
                         id={'schedule-edit-button'}
                         onClick={() => this.setState({editing:true})}>
                      <div className="icon-button-circle">
                        <Edit style={{color:'#2D81C9', fontSize:'25px'}}/>
                      </div>
                      
                      <div className="icon-button-container-text">
                        {i18next.t('EDIT')}
                      </div>
                    </div>
                  }
                </div>
                
                {!editing ?
                  <LabeledMultipleSelectDropDown className="schedule-selected-groups-dropdown"
                                                 value={selectedGroupOptions}
                                                 options={groupOptions}
                                                 placeholder={i18next.t('SELECTED_GROUPS')}
                                                 handleSave={(aOptionsArray) => {
                                                   let groups = [];
                                                   let addedGroups = [];
                                                   let removedGroups = [];
                    
                                                   for(let groupOption of aOptionsArray){
                                                     groups.push({id:groupOption.value, name:groupOption.label});
                                                     let added = true;
                      
                                                     for(let oldGroup of schedule.draft_schedule_version.groups){
                                                       if(oldGroup.id === groupOption.value){
                                                         added = false;
                                                         break;
                                                       }
                                                     }
                                                     if(added){
                                                       addedGroups.push({id:groupOption.value, name:groupOption.label});
                                                     }
                                                   }
                                                   for(let oldGroup of schedule.draft_schedule_version.groups){
                                                     let removed = true;
                      
                                                     for(let groupOption of aOptionsArray){
                                                       if(oldGroup.id === groupOption.value){
                                                         removed = false;
                                                         break;
                                                       }
                                                     }
                                                     if(removed){
                                                       removedGroups.push({id:oldGroup.id, name:oldGroup.name});
                                                     }
                                                   }
                                                   schedule.draft_schedule_version.groups = groups;
                                                   this.setSchedule(schedule, () => this.updateSchedule(null, null, addedGroups, removedGroups));
                                                 }}
                  />
                  :
                  null
                }
              </div>
              :
              null
            }
          </div>
          
          {!editing && !patient ?
            <LabeledDropdown className="schedule-search-patients-dropdown"
                             placeholder={i18next.t('SEARCH_PATIENTS')}
                             value={searchedPatient}
                             handleSave={(aOption) => {
                               if(aOption.value === null){
                                 this.setState({searchedPatient:null});
                               }
                               else{
                                 this.setState({searchedPatient:aOption});
                               }
                             }}
                             isClearable={true}
                             showSearchIcon={true}
                             fetchObjects={(aLimit, aOffset, aSearchString) => fetchPatientsInSchedule(aLimit, aOffset, aSearchString)}
                             labelPropertiesArray={['first_name']}
                             valuePropertiesArray={['id']}
                             optionsArrayFormatter={(aArray) => {
                               let optionsArray = [];
              
                               for(let patient of aArray){
                                 optionsArray.push({label:formatUserName(patient), value:patient.id, key:patient.id});
                               }
                               return optionsArray;
                             }}
                             responseTotalKeysArray={['data', 'data', 'user_page', 'total']}
                             responseObjectKeysArray={['data', 'data', 'user_page', 'users']}
            />
            :
            null
          }
        </div>
        
        {currentPatient ?
          <div className="schedule-patient-info-container">
            <div className="schedule-patient-info-legend-container">
              {legendArray.map((aLegend) =>
                <span className="schedule-patient-info-legend">
                  <div style={{width:'15px', height:'15px', borderRadius:'10px', marginLeft:'auto', marginRight:'auto', backgroundColor:aLegend.colour}}/>
                  
                  <div className="schedule-patient-info-text">
                    {i18next.t(aLegend.labelKey)}
                  </div>
                </span>
              )}
            </div>
            
            <div className="schedule-patient-info-summary-container">
              <div className="schedule-patient-info-header-container">
                <div>
                  {i18next.t('PATIENT')}
                </div>
                
                <div>
                  {i18next.t('GROUPS')}
                </div>
                
                <div style={{cursor:'pointer'}}
                     onClick={() => this.setState({groupsModalOpen:true})}>
                  {i18next.t('VIEW_OTHER_GROUPS')}
                </div>
              </div>
              
              <div className="schedule-separator"></div>
              
              {/*<Separator style={{marginTop:5, marginBottom: 5}} colour={'#FFFFFF'}/>*/}
              
              <div className="schedule-patient-info-details-container">
                <div className="schedule-patient-info-details-patient">
                  <div>
                    {formatUserName(currentPatient)}
                  </div>
                  
                  <div>
                    {currentPatient.email}
                  </div>
                </div>
                
                <div className="schedule-patient-info-group">
                  {currentPatient.groups.map((aGroup) =>
                    <div className="schedule-patient-info-group-cell">
                      {aGroup.name && aGroup.name.length > 25 ? aGroup.name.substring(0, 25) : aGroup.name}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          :
          null
        }
        
        <div className={currentPatient ? 'schedule-dynamic-list-when-searching-patient' : 'schedule-dynamic-list'}>
          <DynamicList id="schedule-builder"
                       showMenu={false}
                       showTotal={false}
                       showBorder={true}
                       downloadCSV={downloadSchedule ? () => {
                           this.setState({downloadSchedule:false});
                           return schedule.name;
                         }
                         :
                         null
                       }
                       columnsArray={columnsArray}
                       showSearchBar={false}
                       explanationKey={editing && schedule && schedule.id && schedule.id.length > 0 ? 'SCHEDULE_EDIT_MODE_EXPLANATION' : null}
                       minColumnWidth={200}
                       clampFirstColumn={true}
                       fixedObjectsArray={fixedObjectsArray}
                       sectionHeadersArray={sectionHeadersArray}
          />
        </div>
        
        <PublishScheduleModal open={confirmPublishModalOpen}
                              save={(aTriggerAddedToGroupEvents) => this.setState({confirmPublishModalOpen:false},
                             () => publishSchedule(schedule.id, aTriggerAddedToGroupEvents).then((newResult) => {
                               notifySchedulePublishSuccess();
                               this.setSchedule(schedule, () => this.updateSchedule());
                             }, (newError) => {
                               notifySchedulePublishError(errorMessageFromServerError(newError));
                             })
                           )}
                              close={() => this.setState({confirmPublishModalOpen:false})}
                              presentedInsideParentModal={presentedInsideParentModal}
        />
        
        <ConfirmationModal isOpen={confirmRemoveSurveyModalOpen}
                           title={i18next.t('REMOVE_SCHEDULE_SURVEY')}
                           reject={() => this.setState({selectedSurvey:null, confirmRemoveSurveyModalOpen:false})}
                           confirm={() => {
                             // TODO: Remove survey locally first.
          
                             for(let index = 0; index < schedule.draft_schedule_version.survey_version_collections.length; index += 1){
                               let survey = schedule.draft_schedule_version.survey_version_collections[index];
            
                               if(survey.id === selectedSurvey.id){
                                 schedule.draft_schedule_version.survey_version_collections.splice(index, 1);
                                 break;
                               }
                             }
                             this.setSchedule(schedule, () => this.updateSchedule(null, [selectedSurvey]));
                             this.setState({selectedSurvey:null, confirmRemoveSurveyModalOpen:false});
                           }}
                           description={i18next.t('REMOVE_SCHEDULE_SURVEY_DESCRIPTION', {aName:selectedSurvey ? selectedSurvey.name : ''})}
                           presentedInsideParentModal={presentedInsideParentModal}
        />
        
        <AddSurveyModal open={addSurveyModalOpen}
                        save={(aSurvey) => this.setState({addSurveyModalOpen:false}, () => {
                          schedule.draft_schedule_version.survey_version_collections.push(aSurvey);
                          this.setSchedule(schedule, () => this.updateSchedule([aSurvey]));
                        })}
                        close={() => this.setState({addSurveyModalOpen:false})}
                        excludedSurveys={surveysArray}
                        presentedInsideParentModal={presentedInsideParentModal}
                        fetchSurveyVersionCollections={fetchSurveyVersionCollections}
        />
        
        <ScheduleTriggerModal open={scheduleTriggerModalOpen}
                              save={(aTrigger) => {
                                const promise = saveScheduleTrigger(schedule.id, aTrigger);
                                promise.then((newResult) => {
                                  fetchSchedule(schedule.id).then((newScheduleResult) => {
                                    this.setSchedule(newScheduleResult.data.data.schedule);
                                  }, (newScheduleError) => {
              
                                  });
                                }, (newError) => {
            
                                });
                                return promise;
                              }}
                              close={() => this.setState({trigger:null, surveyId:null, triggerEditable:true, scheduleTriggerModalOpen:false})}
                              company={company}
                              trigger={trigger}
                              disabled={disabled || !triggerEditable}
                              surveyId={surveyId}
                              languages={languages}
                              triggerType={triggerType}
                              scheduleName={schedule.name}
                              surveysArray={surveysArray}
                              removeScheduleTrigger={(aTriggerId) => {
                                const promise = removeScheduleTrigger(schedule.id, aTriggerId);
                                promise.then((newResult) => {
                                  fetchSchedule(schedule.id).then((newScheduleResult) => {
                                    this.setSchedule(newScheduleResult.data.data.schedule);
                                  }, (newScheduleError) => {
                                  
                                  });
                                }, (newError) => {
                                
                                });
                                return promise;
                              }}
                              fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                              presentedInsideParentModal={presentedInsideParentModal}
                              fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                              fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
        />
        
        <PaymentTriggerModal isOpen={paymentTriggerModalOpen}
                             company={company}
                             disabled={disabled}
                             surveyId={selectedSurvey ? selectedSurvey.id : null}
                             surveysArray={surveysArray}
                             paymentTrigger={paymentTrigger}
                             handleCloseModal={() => this.setState({paymentTrigger:null, selectedSurvey:null, paymentTriggerModalOpen:false})}
                             createPaymentTrigger={createPaymentTrigger}
                             updatePaymentTrigger={updatePaymentTrigger}
                             handleCloseModalAndReload={() => this.setState({paymentTrigger:null, selectedSurvey:null, paymentTriggerModalOpen:false}, () => {
                               fetchSchedule(schedule.id).then((newScheduleResult) => {
                                 this.setSchedule(newScheduleResult.data.data.schedule);
                               }, (newScheduleError) => {
    
                               });
                             })}
                             presentedInsideParentModal={presentedInsideParentModal}
                             fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
        />
        
        <ScheduleVersionsModal open={scheduleVersionsModalOpen}
                               close={() => this.setState({scheduleVersion:null, scheduleVersionsModalOpen:false})}
                               selectScheduleVersion={(aScheduleVersion) => this.setState({scheduleVersion:aScheduleVersion, scheduleVersionsModalOpen:false})}
                               presentedInsideParentModal={presentedInsideParentModal}
                               fetchScheduleVersionsForSchedule={(aLimit, aOffset) => fetchScheduleVersionsForSchedule(schedule.id, aLimit, aOffset)}
        />
        
        <GroupsModal open={groupsModalOpen}
                     close={() => this.setState({groupsModalOpen:false})}
                     groups={currentPatient ? currentPatient.groups : (schedule.draft_schedule_version ? schedule.draft_schedule_version.groups : [])}
                     presentedInsideParentModal={presentedInsideParentModal}
        />
  
        <SurveyDrawer open={isSurveyDrawerOpen}
                      title={selectedSurvey ? selectedSurvey.title : ''}
                      userId={currentPatient ? currentPatient.id : null}
                      adminId={user && user.id}
                      company={company}
                      onClose={() => this.setState({isSurveyDrawerOpen:false})}
                      editable={company && editPermissions.includes(role)}
                      surveyId={selectedSurvey ? selectedSurvey.id : null}
                      onFinishedSurvey={() => this.setState({isSurveyDrawerOpen:false})}
                      surveyResponseId={selectedResponse ? selectedResponse.id : null}
        />
      </div>
    )
  }
}
